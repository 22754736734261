import { Model } from '@vuex-orm/core'
import Member from './Member'
import Phone from './Phone'
import Email from './Email'
import Address from './Address'
import InformationCard from './InformationCard'
import InformationCardContact from './InformationCardContact'

export default class Contact extends Model {
  static entity = 'contacts';

  static fields () {
    return {
      id: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      first_name: this.string('').nullable(),
      last_name: this.string('').nullable(),
      salution: this.string('').nullable(),
      user_id: this.attr(null),
      emails: this.hasMany(Email, 'person_id'),
      phones: this.hasMany(Phone, 'person_id'),
      addresses: this.hasMany(Address, 'person_id'),
      member: this.belongsTo(Member, 'user_id'),
      information_card_contact: this.hasOne(
        InformationCardContact,
        'contact_id'
      ),
      infomation_cards: this.belongsToMany(
        InformationCard,
        InformationCardContact,
        'information_card_id',
        'contact_id'
      )
    }
  }

  get name () {
    return `${this.first_name} ${this.last_name}`
  }

  get phoneArray () {
    if (typeof this.phones !== 'undefined' && Array.isArray(this.phones)) {
      const phones = this.phones.map(function (phone) {
        let number = phone.phone_number
        number = number
          .replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        return number + ' [' + phone.type + ']'
      })
      return phones
    }
    return `${this.first_name} ${this.last_name}`
  }
}
