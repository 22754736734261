import { Model } from '@vuex-orm/core'

export default class Address extends Model {
  static entity = 'addresses';

  static fields () {
    return {
      id: this.attr(null),
      person_id: this.attr(null),
      address1: this.attr(null),
      address2: this.attr(null),
      city: this.attr(null),
      state: this.attr(null),
      zip: this.attr(null),
      can_recieve_mailings: this.boolean(false),
      is_primary: this.boolean(false),
      created: this.attr(null),
      modified: this.attr(null)
    }
  }
}
