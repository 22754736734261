import { API, MANAGER_API } from '@/utilities/http-common'

const getCart = (registrationId, memberId, programSlug = null) => {
  const props = {
    registration_id: registrationId,
    member_id: memberId
  }
  if (programSlug !== null) {
    props.program = programSlug
  }
  return API.get('/cart-courses', { props: props })
    .then(response => {
      if (response.status === 200) {
        console.log('getCart', props, response.data)
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        console.error('getCart error', props, response.data)
        throw error
      }
    })
}

const touchCart = (registrationId = null, programSlug = null) => {
  const props = {
    test: 'is test'
  }
  if (registrationId !== null) {
    props.registration_id = registrationId
  }
  if (programSlug !== null) {
    props.program = programSlug
  }
  return API.put('/cart_courses/touch', { props: props })
    .then(response => {
      if (response.status === 200) {
        console.log('touch', props, response.data)
        return response.data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        console.error('touch error', props, response.data)
        throw error
      }
    })
}

const renewCart = (registrationId = null, programSlug = null) => {
  const props = {
    test: 'is test'
  }
  if (registrationId !== null) {
    props.registration_id = registrationId
  }
  if (programSlug !== null) {
    props.program = programSlug
  }
  return API.put('/cart_courses/renew', { props: props })
    .then(response => {
      if (response.status === 200) {
        console.log('cart_courses/renew', props, response.data)
        return response.data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        console.error('cart_courses/renew error', props, response.data)
        throw error
      }
    })
}

const getCartCount = () => {
  return API.get('/cart_courses/count')
    .then(response => {
      if (response.status === 200) {
        return response.data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        console.error('getCartCount error', response.data)
        throw error
      }
    })
}

const getCartModified = (registrationId = null) => {
  let path = '/cart_courses/modified'
  if (registrationId !== null) {
    path = path + `/${registrationId}`
  }
  return API.get(path)
    .then(response => {
      if (response.status === 200) {
        return response.data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        console.error('getCartModified error', response.data)
        throw error
      }
    })
}

export { getCart, touchCart, getCartCount, renewCart, getCartModified }
