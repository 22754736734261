import axios from 'axios'
import buildUrl from 'axios/lib/helpers/buildURL'

export const API = axios.create({
  baseURL: '/api'
})

export const MANAGER_API = axios.create({
  baseURL: '/manager'
})
// eslint-disable-next-line no-undef
if (process.env.VUE_APP_RELEASE_STAGE !== 'production') {
  API.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting API Request', request)
    return request
  })

  API.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('API Response:', response)
    return response
  })

  MANAGER_API.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting MANAGER Request', request)
    return request
  })

  MANAGER_API.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('MANAGER Response:', response)
    return response
  })
}

export const getQueryVariable = variable => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted url
 */
export const urlBuilder = (url, params) => {
  return buildUrl(url, params)
}
