import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import database from '@/database'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'
import debounce from 'lodash/debounce'
import * as Sentry from '@sentry/vue'
import {
  ADD_TO_REGISTRATION_BALANCE,
  ALLOW_OVER_ENROLL,
  CLEAR_REGISTRATION,
  END_UPDATING,
  ENROLLED_COURSES_STATUS,
  FILLING_SESSIONS,
  FORCE_PAY_UNPAID,
  GET_CURRENT_REGISTRATION,
  GET_ENROLLED_COURSES,
  GET_ENROLLED_COURSES_COUNT,
  HAS_UNPAID_REGISTRATIONS,
  MEMBER_STATUS,
  REGISTRATION_STATUS,
  SESSION_STATUS,
  SESSIONS_STATUS,
  SET_REGISTRATION,
  SET_REGISTRATION_ID,
  SET_STUDENT_IS_NAPPER,
  SET_STUDENT_T_SHIRT_SIZE,
  START_UPDATING,
  STORE_STATUS,
  UPDATE_CALENDAR,
  UPDATE_CALENDARS, UPDATE_CART_MODIFIED,
  UPDATE_ENROLLED_COURSES,
  UPDATE_ENROLLED_COURSES_COUNT,
  UPDATE_ENROLLMENT,
  UPDATE_MEMBER,
  UPDATE_PROGRAM,
  UPDATE_PROGRAMS,
  UPDATE_REGISTRATION,
  UPDATE_REGISTRATION_MEMBER,
  UPDATE_REGISTRATIONS,
  UPDATE_REOPENED_COURSES,
  UPDATE_SESSION,
  UPDATE_SESSIONS,
  UPDATE_STATES,
  UPDATE_STORE,
  UPDATE_WAITLISTS,
  UPDATE_YEAR
} from '@/constants/mutation-types'
import { API } from '@/utilities/http-common'
import { getCartCount, getCartModified } from '@/api/cartAPI'
import parseISO from 'date-fns/parseISO'

Vue.use(Vuex)

/*********************
 * debounce functions
 */

/**
 * update sessions no more than 2 seconds
 */
const debounceUpdateSessions = debounce(
  function (context, program) {
    context.dispatch(UPDATE_SESSIONS, program)
  },
  2000,
  { maxWait: 5000 }
)

/**
 * get registration no more than every 2 seconds
 */
const debounceGetRegistration = debounce(
  function (context) {
    context.dispatch(UPDATE_REGISTRATION, {
      update: true,
      programSlug: context.getters.program.slug
    })
  },
  1500,
  { maxWait: 3000 }
)

/**
 * update session no more than every 2 seconds
 */
var debounceUpdateSession = debounce(
  function (context, session) {
    context.dispatch(UPDATE_SESSION, session)
  },
  2000,
  { maxWait: 5000 }
)

var debounceUpdateStore = debounce(
  function (context, store) {
    context.dispatch(UPDATE_STORE, store)
  },
  2000,
  { maxWait: 5000 }
)

/**
 * update calendar no more than every 2 seconds
 */
var debounceUpdateCalendar = debounce(
  function (context, calendar) {
    context.dispatch(UPDATE_CALENDAR, calendar)
  },
  2000,
  { maxWait: 5000 }
)

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    /**
         * constants
         */
    days: [
      { bit: 1, name: 'Monday', abbreviation: 'Mo' },
      { bit: 2, name: 'Tuesday', abbreviation: 'Tu' },
      { bit: 4, name: 'Wednesday', abbreviation: 'We' },
      { bit: 8, name: 'Thursday', abbreviation: 'Th' },
      { bit: 16, name: 'Friday', abbreviation: 'Fr' },
      { bit: 32, name: 'Saturday', abbreviation: 'Sa' },
      { bit: 64, name: 'Sunday', abbreviation: 'Su' }
    ],
    grades: [
      {
        level: 'Early Learning Center',
        gradeGroup: [
          { name: 'Young 3', value: 'ELC2' },
          { name: '3 year old', value: 'ELC3' },
          { name: '4 year old', value: 'ELC4' },
          { name: 'Pre - K', value: 'Pre-K' }
        ]
      },
      {
        level: 'Elementary',
        gradeGroup: [
          { name: 'K', value: 'K' },
          { name: '1', value: '1' },
          { name: '2', value: '2' },
          { name: '3', value: '3' },
          { name: '4', value: '4' },
          { name: '5', value: '5' }
        ]
      },
      {
        level: 'Middle School',
        gradeGroup: [
          { name: '6', value: '6' },
          { name: '7', value: '7' },
          { name: '8', value: '8' }
        ]
      },
      {
        level: 'High School',
        gradeGroup: [
          { name: '9', value: '9' },
          { name: '10', value: '10' },
          { name: '11', value: '11' },
          { name: '12', value: '12' }
        ]
      },
      {
        level: 'Other',
        gradeGroup: [
          { name: 'Adult', value: 'Adult' },
          { name: 'NA', value: 'NA' }
        ]
      }
    ],
    schools: [
      {
        level: 'Early Learning Center',
        schoolGroup: [{ name: 'Valley View ELC', value: 'VVP' }]
      },
      {
        level: 'Elementary',
        schoolGroup: [
          { name: 'Canyon View', value: 'CV' },
          { name: 'Manzanita', value: 'MZ' },
          { name: 'Sunrise Drive', value: 'SD' },
          { name: 'Ventana Vista', value: 'VV' }
        ]
      },
      {
        level: 'Middle School & High School',
        schoolGroup: [
          { name: 'Esperero Canyon Middle School', value: 'EC' },
          { name: 'Orange Grove Middle School', value: 'OG' },
          { name: 'Foothills High School', value: 'FH' }
        ]
      },
      {
        level: 'Other',
        schoolGroup: [
          { name: 'Out of District', value: 'OD' },
          { name: 'Not Applicable', value: 'NA' }
        ]
      }
    ],
    t_shirt_sizes: [
      { id: 'cxs', size: 'Child X-SM' },
      { id: 'cs', size: 'Child SM' },
      { id: 'cm', size: 'Child MD' },
      { id: 'cl', size: 'Child LG' },
      { id: 'xs', size: 'Adult X-SM' },
      { id: 's', size: 'Adult SM' },
      { id: 'm', size: 'Adult M' },
      { id: 'l', size: 'Adult L' },
      { id: 'xl', size: 'Adult XL' },
      { id: 'xxl', size: 'Adult XXL' },
      { id: 'xxxl', size: 'Adult XXDL' }
    ],
    states: [{ key: 'AL', value: 'Alabama' },
      { key: 'AK', value: 'Alaska' },
      { key: 'AZ', value: 'Arizona' },
      { key: 'AR', value: 'Arkansas' },
      { key: 'CA', value: 'California' },
      { key: 'CO', value: 'Colorado' },
      { key: 'CT', value: 'Connecticut' },
      { key: 'DE', value: 'Delaware' },
      { key: 'DC', value: 'District of Columbia' },
      { key: 'FL', value: 'Florida' },
      { key: 'GA', value: 'Georgia' },
      { key: 'HI', value: 'Hawaii' },
      { key: 'ID', value: 'Idaho' },
      { key: 'IL', value: 'Illinois' },
      { key: 'IN', value: 'Indiana' },
      { key: 'IA', value: 'Iowa' },
      { key: 'KS', value: 'Kansas' },
      { key: 'KY', value: 'Kentucky' },
      { key: 'LA', value: 'Louisiana' },
      { key: 'ME', value: 'Maine' },
      { key: 'MD', value: 'Maryland' },
      { key: 'MA', value: 'Massachusetts' },
      { key: 'MI', value: 'Michigan' },
      { key: 'MN', value: 'Minnesota' },
      { key: 'MS', value: 'Mississippi' },
      { key: 'MO', value: 'Missouri' },
      { key: 'MT', value: 'Montana' },
      { key: 'NE', value: 'Nebraska' },
      { key: 'NV', value: 'Nevada' },
      { key: 'NH', value: 'New Hampshire' },
      { key: 'NJ', value: 'New Jersey' },
      { key: 'NM', value: 'New Mexico' },
      { key: 'NY', value: 'New York' },
      { key: 'NC', value: 'North Carolina' },
      { key: 'ND', value: 'North Dakota' },
      { key: 'OH', value: 'Ohio' },
      { key: 'OK', value: 'Oklahoma' },
      { key: 'OR', value: 'Oregon' },
      { key: 'PA', value: 'Pennsylvania' },
      { key: 'RI', value: 'Rhode Island' },
      { key: 'SC', value: 'South Carolina' },
      { key: 'SD', value: 'South Dakota' },
      { key: 'TN', value: 'Tennessee' },
      { key: 'TX', value: 'Texas' },
      { key: 'UT', value: 'Utah' },
      { key: 'VT', value: 'Vermont' },
      { key: 'VA', value: 'Virginia' },
      { key: 'WA', value: 'Washington' },
      { key: 'WV', value: 'West Virginia' },
      { key: 'WI', value: 'Wisconsin' },
      { key: 'WY', value: 'Wyoming' }],
    /**
         * members
         */
    member: null,
    memberStatus: 'empty',
    /**
         * registration
         */
    registrationsStatus: {
      enrichment: 'empty',
      kinder_plus: 'empty',
      new_care: 'empty',
      outdoor: 'empty',
      summer: 'empty',
      summer_hs: 'empty',
      summer_vvp: 'empty',
      vvelc: 'empty'
    },
    registrations: {
      enrichment: {},
      kinder_plus: {},
      new_care: {},
      outdoor: {},
      summer: {},
      summer_hs: {},
      summer_vvp: {},
      vvelc: {}
    },
    registration_id: 0,
    registration: {
      id: null,
      date: '',
      member: null,
      member_id: 0,
      students: [],
      amountDue: 0,
      amountPaid: 0,
      balance: 0,
      closed: false,
      credit: 0,
      fee: 0,
      registrationFee: 0,
      subTotal: 0,
      type: null,
      year: 0,
      hasGaps: false,
      gaps: [],
      hasOverlaps: false,
      overlaps: [],
      pendingAmountDue: 0,
      pendingSubTotal: 0,
      pendingRegistrationFee: 0,
      pendingDiscountTotal: 0,
      pending_enrollments: [],
      has_lunch_gap: 0,
      lunch_gaps: [],
      needs_sizes: [],
      validated_students: [],
      waitlists: [],
      enroll_without_payment: false

    },
    /**
         * sessions and calendars
         */
    courses: [],
    reOpenedCourses: [],
    programs: {},
    program: {},
    sessions: {
      sessions: [],
      selected: {
        id: 0
      },
      store: { id: 0 }
    },
    sessionsStatus: 'pending',
    sessionStatus: 'pending',
    storeStatus: 'pending',
    calendars: {
      calendars: [],
      selected: { id: 0 }
    },
    /**
         * enrollments
         */
    enrolled_courses_status: 'pending',
    enrolled_courses: {
      count: 0,
      enrichment: {},
      kinder_plus: {},
      new_care: {},
      outdoor: {},
      summer: {},
      summer_hs: {},
      summer_vvp: {},
      vvelc: {}
    },
    cartModified: null,
    waitlist: [],
    waitlists: [],
    updating: {
      sessions: false,
      calendars: false,
      registrations: false
    },
    /**
         * settings
         */
    year: 0,
    allowOverEnroll: false,
    forcePayUnpaid: false,
    hasUnpaidRegistrations: false
  },
  getters: {
    /*******
         * Members
         */
    loggedIn: function (state) {
      return !isNil(state.member) && !isEmpty(state.member)
    },
    isAdmin: state => {
      if (
        !isNil(state.member) &&
                !isNil(state.member.role) &&
                state.member.role === 'admin'
      ) {
        return true
      }
      return false
    },
    isProgramManager: (state, getters) => {
      if (
        getters.isAdmin ||
                (!isNil(state.member) &&
                    !isNil(state.member.role) &&
                    state.member.role === 'program')
      ) {
        return true
      }
      return false
    },
    isSiteManager: (state, getters) => {
      if (
        getters.isProgramManager ||
                (!isNil(state.member) &&
                    !isNil(state.member.role) &&
                    state.member.role === 'site')
      ) {
        return true
      }
      return false
    },
    gteManager: (state, getters) => {
      return getters.isProgramManager || getters.isAdmin
    },
    gteSite: (state, getters) => {
      return getters.isProgramManager || getters.isAdmin
    },
    member: state => {
      return state.member
    },
    memberName: state => {
      if (!isNil(state.member) && !isNil(state.member.name)) {
        return state.member.name
      } else {
        return ''
      }
    },
    memberStatus: state => {
      return state.memberStatus
    },

    /***********
         * Registrations
         */
    registration_id: state => {
      if (!isNil(state.registration_id) && state.registration_id > 0) {
        return state.registration_id
      } else {
        if (
          !isNil(state.registration) &&
                    !isNil(state.registration) &&
                    !isNil(state.registration.id)
        ) {
          return state.registration.id
        }
      }
      return 0
    },
    registrationMember: state => {
      if (typeof state.registration.member !== 'undefined') {
        return state.registration.member
      } else {
        return null
      }
    },
    registration: state => {
      return state.registration
    },
    registrationBySlug: state => slug => {
      if (!isNil(state.registrations[slug])) {
        return state.registrations[slug]
      } else {
        return {}
      }
    },
    students: state => {
      return state.registration.students
    },
    registrations: state => {
      return state.registrations
    },
    registrationStatus: (state, getters) => {
      return getters.registrationStatusBySlug(state.program.slug)
    },
    registrationStatusBySlug: state => slug => {
      return state.registrationsStatus[slug]
    },
    amountDue: state => {
      return state.registration.amountDue - state.registration.amountPaid
    },
    pendingAmountDue: state => {
      return state.registration.pendingAmountDue
    },
    balance: (state, getters) => {
      return (
        state.registration.amountPaid -
                getters.registrationTotal -
                getters.pendingTotal
      )
    },
    forcePayUnpaid: state => {
      return state.forcePayUnpaid
    },
    allowOverEnroll: state => {
      return state.allowOverEnroll
    },
    hasUnpaidRegistrations: state => {
      return state.hasUnpaidRegistrations
    },
    blockEnrollmentWithUnpaid: state => {
      return (
        state.hasUnpaidRegistrations && state.forcePayUnpaid
      )
    },

    /***********
         * Registration status by program
         */
    careStatus: (state, getters) => {
      return getters.registrationStatusBySlug('care')
    },
    enrichmentStatus: (state, getters) => {
      return getters.registrationStatusBySlug('enrichment')
    },
    outdoorStatus: (state, getters) => {
      return getters.registrationStatusBySlug('outdoor')
    },
    summerStatus: (state, getters) => {
      return getters.registrationStatusBySlug('summer')
    },
    summerHsStatus: (state, getters) => {
      return getters.registrationStatusBySlug('summer_hs')
    },
    summerVvpStatus: (state, getters) => {
      return getters.registrationStatusBySlug('summer_vvp')
    },
    vvelcStatus: (state, getters) => {
      return getters.registrationStatusBySlug('vvelc')
    },
    newCareStatus: (state, getters) => {
      return getters.registrationStatusBySlug('new_care')
    },
    kinderPlusStatus: (state, getters) => {
      return getters.registrationStatusBySlug('kinder_plus')
    },
    /***********
         * Program
         */
    program: state => {
      return state.program
    },
    programs: state => {
      return state.programs
    },

    /***********
         * Sessions
         */
    sessions: state => {
      return state.sessions.sessions
    },
    selectedSession: state => {
      return state.sessions.selected
    },
    selectedStore: state => {
      return state.sessions.store
    },
    sessionsStatus: state => {
      return state.sessionsStatus
    },
    sessionStatus: function (state) {
      return state.sessionStatus
    },
    storeStatus: function (state) {
      return state.storeStatus
    },
    hasReopenedCourses: state => {
      return state.reOpenedCourses.length > 0
    },
    reopenedCourses: state => {
      return state.reOpenedCourses
    },

    /***********
         * Calendars
         */
    calendars: function (state) {
      return state.calendars.calendars
    },
    selectedCalendar: state => {
      return state.calendars.selected
    },
    specialDayTemplates: (state, getters) => specialDay => {
      const dayType =
                typeof specialDay === 'undefined' ? 'normal' : specialDay.type
      let templates = []
      const calendar = getters.selectedCalendar
      if (
        typeof calendar.specialDayTemplates !== 'undefined' &&
                typeof calendar.specialDayTemplates[dayType] !== 'undefined'
      ) {
        return calendar.specialDayTemplates[dayType]
      }
      const startTime = moment(calendar.start_time)
      const endTime = moment(calendar.end_time)
      const open = moment(calendar.open)
      const close = moment(calendar.close)
      const earlyRelease = moment(calendar.early_release)
      const lateStart = moment(calendar.late_start)
      const halfDayStart = moment(calendar.half_day_start)
      const halfDayEnd = moment(calendar.half_day_end)
      let starts = getters.templateByStart
      let ends = getters.templateByStart
      const templatesByStart = getters.templateByStart
      switch (dayType) {
        case 'normal': // regular
          starts = starts[open]
          if (typeof starts !== 'undefined') {
            starts = starts.filter(function (template) {
              return moment(template.endTime).isSame(startTime)
            })
          } else {
            starts = []
          }
          ends = []
          if (calendar.allow_sequential_templates) {
            return calendar.templates
            // while (typeof templatesByStart[currentEndTime] !== "undefined") {
            //   let newEnds = templatesByStart[currentEndTime];
            //   newEnds = newEnds.filter(function(template) {
            //     let templateEndTime = moment(template.endTime);
            //     let value = moment(template.startTime).isSame(currentEndTime);
            //     if (currentEndTime.isBefore(templateEndTime)) {
            //       currentEndTime = templateEndTime;
            //     }
            //     return value;
            //   });
            // }
          } else {
            if (calendar.allow_template_gap) {
              ends = []
              for (const timeForTemplate in templatesByStart) {
                const templatesAtStartTime = templatesByStart[timeForTemplate]
                templatesAtStartTime.forEach(template => {
                  if (endTime.isSameOrBefore(moment(template.startTime))) {
                    ends.push(template)
                  }
                })
              }
            } else {
              ends = templatesByStart[endTime]
              if (typeof ends !== 'undefined') {
                ends = ends.filter(function (template) {
                  return moment(template.endTime).isSame(close)
                })
              } else {
                ends = []
              }
            }
          }
          templates = templates.concat(starts).concat(ends)
          break
        case 'full_day': {
          const fullDayTemplate = getters.selectedCalendar.templates.find(
            function (template) {
              return template.name === 'Full Day'
            }
          )
          templates.push(fullDayTemplate)
          break
        }
        case 'half_day': {
          starts = starts[open]
          if (typeof starts !== 'undefined') {
            starts = starts.filter(function (template) {
              return moment(template.endTime).isSame(halfDayStart)
            })
          } else {
            starts = []
          }
          if (calendar.allow_template_gap) {
            ends = []
            for (const timeForTemplate in templatesByStart) {
              const templatesAtStartTime = templatesByStart[timeForTemplate]
              templatesAtStartTime.forEach(template => {
                if (halfDayEnd.isSameOrBefore(moment(template.startTime))) {
                  ends.push(template)
                }
              })
            }
          } else {
            ends = ends[halfDayEnd]
            if (typeof ends !== 'undefined') {
              ends = ends.filter(function (template) {
                return moment(template.endTime).isSameOrBefore(close)
              })
            } else {
              ends = []
            }
          }
          templates = templates.concat(starts).concat(ends)
          break
        }
        case 'early_release': {
          starts = starts[open]
          if (typeof starts !== 'undefined') {
            starts = starts.filter(function (template) {
              return moment(template.endTime).isSame(startTime)
            })
          } else {
            starts = []
          }
          if (calendar.allow_template_gap) {
            ends = []
            for (const timeForTemplate in templatesByStart) {
              const templatesAtStartTime = templatesByStart[timeForTemplate]
              templatesAtStartTime.forEach(template => {
                if (earlyRelease.isSameOrBefore(moment(template.startTime))) {
                  ends.push(template)
                }
              })
            }
          } else {
            ends = ends[earlyRelease]
            if (typeof ends !== 'undefined') {
              ends = ends.filter(function (template) {
                return moment(template.endTime).isSame(close)
              })
            } else {
              ends = []
            }
          }
          templates = templates.concat(starts).concat(ends)
          break
        }
        case 'late_start': {
          starts = starts[open]
          if (typeof starts !== 'undefined') {
            if (calendar.allow_template_gap) {
              starts = starts.filter(function (template) {
                return lateStart.isSameOrAfter(moment(template.endTime))
              })
            } else {
              starts = starts.filter(function (template) {
                return moment(template.endTime).isSame(lateStart)
              })
            }
          } else {
            starts = []
          }
          if (calendar.allow_template_gap) {
            ends = []
            for (const timeForTemplate in templatesByStart) {
              const templatesAtStartTime = templatesByStart[timeForTemplate]
              templatesAtStartTime.forEach(template => {
                if (endTime.isSameOrBefore(moment(template.startTime))) {
                  ends.push(template)
                }
              })
            }
          } else {
            ends = ends[endTime]
            if (typeof ends !== 'undefined') {
              ends = ends.filter(function (template) {
                return moment(template.endTime).isSame(close)
              })
            } else {
              ends = []
            }
          }
          templates = templates.concat(starts).concat(ends)
          break
        }
      }
      if (typeof calendar.specialDayTemplates === 'undefined') {
        calendar.specialDayTemplates = {}
      }

      if (!calendar.allow_template_overlap && templates.length > 1) {
        console.log('specialDayTemplates', dayType, templates)
        const nonOverlaps = []
        let template = templates.shift()
        while (templates.length > 0) {
          let isContained = false
          templates.forEach(testTemplate => {
            const templateStart = moment(template.startTime)
            const templateEnd = moment(template.endTime)
            const testStart = moment(testTemplate.startTime)
            const testEnd = moment(testTemplate.endTime)
            console.log('overlap', dayType, templateStart.format('LT'), templateEnd.format('LT'), testStart.format('LT'), testEnd.format('LT'), templateStart.isSameOrAfter(testStart), templateEnd.isSameOrBefore(testEnd))
            if (templateStart.isSameOrAfter(testStart) && templateEnd.isSameOrBefore(testEnd)) {
              isContained = true
            }
          })
          if (!isContained) {
            nonOverlaps.push(template)
          }
          template = templates.shift()
        }
        let isContained = false
        nonOverlaps.forEach(testTemplate => {
          const templateStart = moment(template.startTime)
          const templateEnd = moment(template.endTime)
          const testStart = moment(testTemplate.startTime)
          const testEnd = moment(testTemplate.endTime)
          console.log('overlap secondary', dayType, templateStart.format('LT'), templateEnd.format('LT'), testStart.format('LT'), testEnd.format('LT'), templateStart.isSameOrAfter(testStart), templateEnd.isSameOrBefore(testEnd))
          if (templateStart.isSameOrAfter(testStart) && templateEnd.isSameOrBefore(testEnd)) {
            isContained = true
          }
        })
        if (!isContained) {
          nonOverlaps.push(template)
        }
        templates = nonOverlaps
        console.log('specialDayTemplates after', dayType, templates)
      }

      calendar.specialDayTemplates[dayType] = templates
      return templates
    },
    templateByStart: (state, getters) => {
      // eslint-disable-next-line no-unused-vars
      const calendar = getters.selectedCalendar
      const templates = getters.selectedCalendar.templates
      return groupBy(templates, function (template) {
        return moment(template.startTime)
      })
    },
    templateByEnd: (state, getters) => {
      const templates = getters.selectedCalendar.templates
      return groupBy(templates, function (template) {
        return moment(template.endTime)
      })
    },

    /***********
         * Enrollments
         */
    cartModified: function (state) {
      return state.cartModified
    },
    enrolledCourses: function (state) {
      const slug = state.program.slug
      return state.enrolled_courses[slug]
    },
    programsEnrolledCourses: function (state) {
      return state.enrolled_courses
    },
    enrolledCount: function (state) {
      return state.enrolled_courses.count
    },
    waitlist: function (state) {
      return state.waitlist
    },
    waitlists: function (state) {
      return state.waitlists
    },
    /***********
         * Shared Global State
         */
    year: state => {
      return state.year
    },
    grades: function (state) {
      return state.grades
    },
    schools: function (state) {
      return state.schools
    },
    states: function (state) {
      return state.states
    },
    t_shirt_sizes: function (state) {
      return state.t_shirt_sizes
    },
    tShirtSize: state => studentForSize => {
      const student = state.registration.students.find(student => {
        return studentForSize.student_id === student.id
      })
      return student.registration_student.t_shirt_size
    },
    isUpdating: state => {
      return state.updating.registrations ||
                state.updating.calendars ||
                state.updating.sessions
    }
  },
  mutations: {
    /***********
         * Session Mutate
         */
    [UPDATE_SESSIONS] (state, sessions) {
      Vue.set(state.sessions, 'sessions', sessions)
    },
    [UPDATE_SESSION] (state, session) {
      Vue.set(state.sessions, 'selected', session)
    },
    [UPDATE_STORE] (state, session) {
      Vue.set(state.sessions, 'store', session)
    },
    [SESSION_STATUS] (state, status) {
      Vue.set(state, 'sessionStatus', status)
    },
    [SESSIONS_STATUS] (state, status) {
      Vue.set(state, 'sessionsStatus', status)
    },
    [STORE_STATUS] (state, status) {
      Vue.set(state, 'storeStatus', status)
    },
    [UPDATE_REOPENED_COURSES] (state, payload) {
      state.reOpenedCourses = payload
    },
    /***********
         * Calendar Mutate
         */
    [UPDATE_CALENDARS] (state, calendars) {
      Vue.set(state.calendars, 'calendars', calendars)
    },
    [UPDATE_CALENDAR] (state, calendar) {
      Vue.set(state.calendars, 'selected', calendar)
    },

    /***********
         * Enrollment Mutate
         */
    [UPDATE_ENROLLED_COURSES] (state, enrolledCourses) {
      if (!isEmpty(enrolledCourses)) {
        state.enrolled_courses.count = enrolledCourses.count
      } else {
        state.enrolled_courses.count = 0
      }
      if ('programs' in enrolledCourses) {
        // this is the NEW_SIGNUP_CART return
        const programs = enrolledCourses.programs
        Vue.set(
          state.enrolled_courses,
          'enrichment',
          programs.enrichment
        )
        Vue.set(
          state.enrolled_courses,
          'kinder_plus',
          enrolledCourses.kinder_plus
        )
        Vue.set(state.enrolled_courses, 'new_care', programs.new_care)
        Vue.set(state.enrolled_courses, 'outdoor', programs.outdoor)
        Vue.set(state.enrolled_courses, 'summer', programs.summer)
        Vue.set(state.enrolled_courses, 'summer_hs', programs.summer_hs)
        Vue.set(
          state.enrolled_courses,
          'summer_vvp',
          programs.summer_vvp
        )
        Vue.set(state.enrolled_courses, 'vvelc', programs.vvelc)
      } else {
        Vue.set(
          state.enrolled_courses,
          'enrichment',
          enrolledCourses.enrichment
        )
        Vue.set(
          state.enrolled_courses,
          'kinder_plus',
          enrolledCourses.kinder_plus
        )
        Vue.set(state.enrolled_courses, 'new_care', enrolledCourses.new_care)
        Vue.set(state.enrolled_courses, 'outdoor', enrolledCourses.outdoor)
        Vue.set(state.enrolled_courses, 'summer', enrolledCourses.summer)
        Vue.set(state.enrolled_courses, 'summer_hs', enrolledCourses.summer_hs)
        Vue.set(
          state.enrolled_courses,
          'summer_vvp',
          enrolledCourses.summer_vvp
        )
        Vue.set(state.enrolled_courses, 'vvelc', enrolledCourses.vvelc)
      }
    },
    [UPDATE_ENROLLED_COURSES_COUNT] (state, count) {
      state.enrolled_courses.count = count
    },
    [UPDATE_CART_MODIFIED] (state, status) {
      Vue.set(state, 'cartModified', status)
    },
    [ENROLLED_COURSES_STATUS] (state, status) {
      Vue.set(state, 'enrolled_courses_status', status)
    },
    [UPDATE_WAITLISTS] (state, payload) {
      Vue.set(state, 'waitlists', payload)
    },

    /***********
         * Registration Mutate
         */
    [SET_REGISTRATION_ID] (state, payload) {
      state.registration_id = payload
    },
    [SET_REGISTRATION] (state, payload) {
      state.registration.id = payload.id
      state.registration.member = payload.member
      state.registration.member_id = payload.member_id
      state.registration.students = payload.students
      state.registration.amountDue = payload.amountDue
      state.registration.amountPaid = payload.amountPaid
      state.registration.balance = payload.balance
      state.registration.closed = payload.closed
      state.registration.fee = payload.fee
      state.registration.registrationFee = payload.registrationFee
      state.registration.subTotal = payload.subTotal
      state.registration.type = payload.type
      state.registration.year = payload.year
      state.registration.pendingSubTotal = payload.pendingSubTotal
      state.registration.pendingDiscountTotal = payload.pendingDiscountTotal
      state.registration.pendingAmountDue =
                +payload.pendingSubTotal + +payload.pendingRegistrationFee
      state.registration.hasGaps = payload.hasGaps
      state.registration.gaps = payload.gaps
      state.registration.hasOverlaps = payload.hasOverlaps
      state.registration.overlaps = payload.overlaps
      state.registration.has_lunch_gap = payload.has_lunch_gap
      state.registration.lunch_gaps = payload.lunch_gaps
      state.registration.date = payload.date
      state.registration.enroll_without_payment = payload.enroll_without_payment
      if (!isNil(payload.pending_enrollments)) {
        state.registration.pending_enrollments = payload.pending_enrollments
      }
      if (!isNil(payload.waitlists)) {
        state.waitlists = payload.waitlists
      }
      if (!isNil(payload.needs_sizes)) {
        state.registration.needs_sizes = payload.needs_sizes
      }
      if (!isNil(payload.validated_students)) {
        state.registration.validated_students = payload.validated_students
      }
      if (!isNil(payload.waitlists)) {
        state.registration.waitlists = payload.waitlists
      } else {
        state.registration.waitlists = []
      }
    },
    [UPDATE_REGISTRATION] (state, payload) {
      var registration = payload.registration
      const programSlug = payload.programSlug
      Vue.set(state.registrations, programSlug, registration)
    },
    [ADD_TO_REGISTRATION_BALANCE] (state, amount) {
      Vue.set(state.registration, 'balance', state.registration.balance + amount)
    },
    [CLEAR_REGISTRATION] (state) {
      state.registration.id = 0
      state.registration.member = null
      state.registration.students = []
      state.registration.amountDue = 0
      state.registration.amountPaid = 0
      state.registration.closed = false
      state.registration.fee = 0
      state.registration.registrationFee = 0
      state.registration.subTotal = 0
      state.registration.type = 'default'
      state.registration.year = 0
      state.registration.pendingAmountDue = 0
      state.registration.pendingSubTotal = 0
      state.registration.pendingDiscountTotal = 0
      state.registration.pendingAmountDue = 0
      state.registration.date = ''
      state.registration.pending_enrollments = []
      state.registration.hasGaps = false
      state.registration.hasOverlaps = false
      state.registration.has_lunch_gap = 0
      state.registration.lunch_gaps = []
      state.registration.needs_sizes = []
    },
    [UPDATE_REGISTRATION_MEMBER] (state, member) {
      Vue.set(state.registration, 'member', member)
    },
    [REGISTRATION_STATUS] (state, status) {
      if (typeof status === 'string') {
        Vue.set(state.registrationsStatus, state.program.slug, status)
      } else {
        const slug = status.slug
        const newStatus = status.status
        Vue.set(state.registrationsStatus, slug, newStatus)
      }
    },
    [HAS_UNPAID_REGISTRATIONS] (state, hasUnpaidRegistrations) {
      state.hasUnpaidRegistrations = hasUnpaidRegistrations
    },
    /***********
         * Student Mutate
         */
    [SET_STUDENT_T_SHIRT_SIZE] (state, payload) {
      const student = state.registration.students.find(student => {
        return payload.student_id === student.id
      })
      student.registration_student.t_shirt_size = payload.size
    },
    [SET_STUDENT_IS_NAPPER] (state, payload) {
      const student = state.registration.students.find(student => {
        return payload.student_id === student.id
      })
      student.registration_student.is_napper = payload.is_napper
    },
    /***********
         * Program Mutate
         */
    [UPDATE_PROGRAM] (state, program) {
      Vue.set(state, 'program', program)
    },
    [UPDATE_PROGRAMS] (state, programs) {
      Vue.set(state, 'programs', programs)
    },

    /***********
         * Members Mutate
         */
    [UPDATE_MEMBER] (state, member) {
      if (!isEmpty(member)) {
        const user = {}
        if (!isEmpty(member.email)) {
          user.email = member.email
        }
        if (!isEmpty(member.username)) {
          user.username = member.username
        }
        if (!isEmpty(member.name)) {
          user.name = member.name
        }
        Sentry.setUser(user)
      } else {
        Sentry.setUser(null)
      }
      Vue.set(state, 'member', member)
    },
    [MEMBER_STATUS] (state, status) {
      Vue.set(state, 'member_status', status)
    },

    /***********
         * Mutate global state
         */
    [FORCE_PAY_UNPAID] (state, forcePayUnpaid) {
      state.forcePayUnpaid = forcePayUnpaid
    },
    [ALLOW_OVER_ENROLL] (state, allowOverEnroll) {
      state.allowOverEnroll = allowOverEnroll
    },
    [START_UPDATING] (state, type) {
      Vue.set(state.updating, type, true)
    },
    [END_UPDATING] (state, type) {
      Vue.set(state.updating, type, false)
    },
    [UPDATE_STATES] (state, states) {
      Vue.set(state, 'states', states)
    },
    [UPDATE_YEAR] (state, year) {
      state.year = year
    }
  },
  actions: {
    [UPDATE_PROGRAM] (context, program) {
      context.commit(UPDATE_PROGRAM, program)
      debounceUpdateSessions(context)
    },
    [UPDATE_YEAR] (context, year) {
      context.commit(UPDATE_YEAR, year)
      debounceUpdateSessions(context)
    },
    [UPDATE_SESSION] (context, session) {
      context.commit(SESSION_STATUS, 'loading')
      context.commit(UPDATE_SESSION, session)
      context.commit(START_UPDATING, 'sessions')
      context.dispatch('wait/start', 'session.get.' + session.id, { root: true })
      const requestUrl =
                'programs/' + context.getters.program.slug + '/sessions/' + session.id
      var params = {
        params: {
          year: context.getters.year,
          programSlug: context.getters.program.slug,
          sessionId: session.id
        }
      }
      if (context.getters.registration_id > 0) {
        params.params.registration_id = context.getters.registration_id
      }
      return new Promise((resolve, reject) => {
        API.get(requestUrl, params)
          .then(response => {
            const data = response.data
            context.commit(END_UPDATING, 'sessions')
            context.dispatch('wait/end', 'session.get.' + session.id, { root: true })
            if (typeof data.session !== 'undefined') {
              context.commit(UPDATE_SESSION, data.session)
              context.commit(SESSION_STATUS, 'success')
              resolve(response)
            } else {
              // alert('Could not retrieve sessions!')
              context.commit(SESSION_STATUS, 'error')
              reject(response)
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'sessions.get.' + session.id, { root: true })
            context.commit(END_UPDATING, 'sessions')
            // alert('Could not retrieve sessions!')
            context.commit(SESSION_STATUS, 'error')
            reject(e)
          })
      })
    },
    [UPDATE_STORE] (context, store) {
      context.commit(STORE_STATUS, 'loading')
      context.commit(UPDATE_STORE, store)
      context.commit(START_UPDATING, 'stores')
      context.dispatch('wait/start', 'store.get.' + store.id, { root: true })
      const requestUrl =
                'programs/' + context.getters.program.slug + '/sessions/' + store.id
      var params = {
        params: {
          year: context.getters.year,
          programSlug: context.getters.program.slug,
          sessionId: store.id
        }
      }
      if (context.getters.registration_id > 0) {
        params.params.registration_id = context.getters.registration_id
      }
      return new Promise((resolve, reject) => {
        API.get(requestUrl, params)
          .then(response => {
            const data = response.data
            context.commit(END_UPDATING, 'stores')
            context.dispatch('wait/end', 'sessions.get.' + store.id, { root: true })
            if (typeof data.session !== 'undefined') {
              context.commit(UPDATE_STORE, data.session)
              context.commit(STORE_STATUS, 'success')
              resolve(response)
            } else {
              alert('Could not retrieve store!')
              context.commit(STORE_STATUS, 'error')
              reject(response)
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'sessions.get.' + store.id, { root: true })
            context.commit(END_UPDATING, 'sessions')
            // alert('Could not retrieve store sessions!')
            context.commit(SESSION_STATUS, 'error')
            reject(e)
          })
      })
    },
    [UPDATE_SESSIONS] (context) {
      context.dispatch('wait/start', 'sessions.get', { root: true })
      context.commit(SESSIONS_STATUS, 'loading')
      if (
        isNil(context.getters.program) ||
                isNil(context.getters.program.slug)
      ) {
        context.commit(SESSIONS_STATUS, 'error')
        return
      }
      const requestUrl = 'programs/' + context.getters.program.slug + '/sessions'
      var params = {
        params: {
          year: context.getters.year,
          programSlug: context.getters.program.slug
        }
      }
      if (context.getters.registration_id > 0) {
        params.params.registration_id = context.getters.registration_id
      }

      return API.get(requestUrl, params).then(
        response => {
          console.log('response', response)

          context.commit(UPDATE_SESSIONS, response.data.sessions)
          context.commit(SESSIONS_STATUS, 'success')
          context.dispatch('wait/end', 'sessions.get', { root: true })
        },
        response => {
          // eslint-disable-next-line
              console.log(response)
          // alert("Could not retrieve sessions!");
          context.dispatch('wait/end', 'sessions.get', { root: true })
          context.commit(SESSIONS_STATUS, 'error')
          return null
        }
      )
    },
    [FILLING_SESSIONS] () {
    },
    /***********
         * Calendar Actions
         */
    [UPDATE_CALENDAR] (context, calendar) {
      context.commit(UPDATE_CALENDAR, calendar)
      context.commit(START_UPDATING, 'calendars')
      context.dispatch('wait/start', 'calendar.get.' + calendar.id, { root: true })

      return new Promise((resolve, reject) => {
        const slug = context.getters.program.slug
        const id = calendar.id
        let month = calendar.month
        if (isUndefined(month) && !isUndefined(calendar.days)) {
          console.warn('isUndefined(month) && !isUndefined(calendar.days)')
          const years = Object.keys(calendar.days)
          if (!isUndefined(calendar.days[years[0]])) {
            console.warn('!isUndefined(calendar.days[years[0]])')
            const months = Object.keys(calendar.days[years[0]])
            month = months[0]
          }
        } else {
          // check the url parameters
          const url = new URL(window.location.href)
          const params = url.searchParams
          if (params.has('month')) {
            month = params.get('month')
          }
        }
        console.warn('month', month, calendar)
        const url = 'programs/' + slug + '/site_calendars/view/' + id
        var params = {
          params: {
            year: context.getters.year,
            programSlug: slug,
            calendar_id: id
          }
        }
        if (!isUndefined(month)) {
          params.params.month = month
        }
        if (context.getters.registration_id > 0) {
          params.params.registration_id = context.getters.registration_id
        }
        API.get(url, params)
          .then(response => {
            const data = response.data
            context.dispatch('wait/end', 'calendar.get.' + calendar.id, { root: true })
            context.commit(END_UPDATING, 'calendars')
            if (typeof data.calendar !== 'undefined' && data.calendar !== null) {
              context.commit(UPDATE_CALENDAR, data.calendar)
              resolve('success')
            } else {
              context.commit(UPDATE_CALENDARS, [])
              resolve('empty')
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'calendar.get.' + calendar.id, { root: true })
            context.commit(END_UPDATING, 'calendars')
            reject(e)
          })
      })
    },
    [UPDATE_CALENDARS] (context) {
      context.commit(START_UPDATING, 'calendars')
      context.dispatch('wait/start', 'calendars.get', { root: true })
      return new Promise((resolve, reject) => {
        const slug = context.getters.program.slug
        const url = 'programs/' + slug + '/site_calendars'
        var params = {
          params: {
            year: context.getters.year,
            programSlug: slug
          }
        }
        if (context.getters.registration_id > 0) {
          params.params.registration_id = context.getters.registration_id
        }
        if (context.getters.registrationMember) {
          params.params.member_id = context.getters.registrationMember.id
        }
        API.get(url, params)
          .then(response => {
            context.commit(END_UPDATING, 'calendars')
            context.dispatch('wait/end', 'calendars.get', { root: true })
            const data = response.data
            if (
              typeof data.site_calendars !== 'undefined' &&
                            data.site_calendars !== null
            ) {
              context.commit(UPDATE_CALENDARS, data.site_calendars)
              resolve('success')
            } else {
              context.commit(UPDATE_CALENDARS, [])
              resolve('empty')
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'calendars.get', { root: true })
            context.commit(END_UPDATING, 'calendars')
            reject(e)
          })
      })
    },
    /***********
         * Registration Actions
         */
    [UPDATE_REGISTRATION] (context, payload) {
      context.commit(START_UPDATING, 'registration')
      const currentRegistration = context.getters.registration
      const isNew = currentRegistration.id == null
      const registrationId = isNew ? 'new' : currentRegistration.id
      context.dispatch('wait/start', 'registrations.get.' + registrationId, { root: true })
      if (isNew) {
        if (context.getters.program.has_calendars) {
          context.dispatch('wait/start', 'calendars.get', { root: true })
        }
        if (context.getters.program.has_sessions) {
          context.dispatch('wait/start', 'sessions.get', { root: true })
        }
      }
      return new Promise((resolve, reject) => {
        let slug
        if (isNil(payload.programSlug)) {
          slug = context.getters.program.slug
        } else {
          slug = payload.programSlug
        }
        if (
          typeof payload !== 'undefined' &&
                    typeof payload.update !== 'undefined' &&
                    payload.update === true
        ) {
          context.commit(REGISTRATION_STATUS, {
            status: 'updating',
            slug: slug
          })
        } else {
          context.commit(REGISTRATION_STATUS, {
            status: 'loading',
            slug: slug
          })
        }

        const url = 'programs/' + slug + '/registrations/current'
        const params = {
          params: {
            year: context.getters.year,
            slug: slug
          }
        }
        if (
          context.getters.registration_id > 0 &&
                    slug === context.getters.program.slug
        ) {
          params.params.registration_id = context.getters.registration_id
        }
        API.get(url, params)
          .then(response => {
            const data = response.data
            context.commit(END_UPDATING, 'registration')
            context.dispatch('wait/end', 'registrations.get.' + registrationId, { root: true })
            if (
              typeof data.registration !== 'undefined' &&
                            data.registration !== null
            ) {
              var payload = {
                programSlug: slug,
                registration: data.registration
              }
              if (slug === context.getters.program.slug) {
                context.commit(SET_REGISTRATION, data.registration)
              }
              context.commit(UPDATE_REGISTRATION, payload)
              context.commit(REGISTRATION_STATUS, {
                status: 'success',
                slug: slug
              })
              if (isNew) {
                if (context.getters.program.has_calendars) {
                  context.dispatch(UPDATE_CALENDARS)
                } else {
                  context.dispatch('wait/end', 'calendars.get', { root: true })
                }
                if (context.getters.program.has_sessions) {
                  context.dispatch(UPDATE_SESSIONS)
                } else {
                  context.dispatch('wait/end', 'sessions.get', { root: true })
                }
              }
            } else {
              context.commit(REGISTRATION_STATUS, {
                status: 'empty',
                slug: slug
              })
            }
            resolve(response)
          })
          .catch(e => {
            context.dispatch('wait/end', 'registrations.get.' + registrationId, { root: true })
            context.dispatch('wait/end', 'sessions.get', { root: true })
            context.dispatch('wait/end', 'calendars.get', { root: true })
            context.commit(END_UPDATING, 'registration')
            reject(e)
          })
      })
    },
    /**
         * @todo (scd) update to use Axios not Vue.http
         * @param context
         * @param payload
         */
    [UPDATE_REGISTRATIONS] (context, payload) {
      const registrations = context.getters.registrations
      context.commit(START_UPDATING, 'registration')
      context.dispatch('wait/start', 'registrations.get', { root: true })
      for (const slug in registrations) {
        if (Object.prototype.hasOwnProperty.call(registrations, slug)) {
          if (slug === context.getters.program.slug) {
            // skip
          } else {
            if (
              typeof payload !== 'undefined' &&
                            typeof payload.update !== 'undefined' &&
                            payload.update === true
            ) {
              context.commit(REGISTRATION_STATUS, {
                status: 'updating',
                slug: slug
              })
            } else {
              context.commit(REGISTRATION_STATUS, {
                status: 'loading',
                slug: slug
              })
            }
            const requestUrl = 'programs/{programSlug}/registrations/current'
            Vue.http
              .get(requestUrl, {
                params: {
                  year: context.getters.year,
                  programSlug: slug
                }
              })
              .then(
                response => {
                  response.json().then(
                    responseJson => {
                      context.commit(END_UPDATING, 'registration')
                      context.dispatch('wait/end', 'registrations.get', { root: true })
                      if (
                        typeof responseJson.registration !== 'undefined' &&
                                                responseJson.registration !== null
                      ) {
                        var payload = {
                          programSlug: slug,
                          registration: responseJson.registration
                        }
                        context.commit(UPDATE_REGISTRATION, payload)
                        context.commit(REGISTRATION_STATUS, {
                          status: 'success',
                          slug: slug
                        })
                      } else {
                        context.commit(REGISTRATION_STATUS, {
                          status: 'empty',
                          slug: slug
                        })
                      }
                    },
                    () => {
                      // eslint-disable-next-line
                                            alert('Could not parse registrations!')
                      context.dispatch('wait/end', 'registrations.get', { root: true })

                      context.commit(REGISTRATION_STATUS, {
                        status: 'error',
                        slug: slug
                      })
                    }
                  )
                },
                response => {
                  // eslint-disable-next-line
                                    console.log(response)
                  context.commit(END_UPDATING, 'registration')
                  context.dispatch('wait/end', 'registrations.get', { root: true })

                  alert('Could not retrieve registrations!')
                  context.commit(REGISTRATION_STATUS, {
                    status: 'error',
                    slug: slug
                  })
                  return null
                }
              )
          }
        }
      }
    },
    [GET_CURRENT_REGISTRATION] (context, payload) {
      payload.programSlug = context.program.slug
      context.dispatch(UPDATE_REGISTRATION, payload)
    },
    /***********
         * Student Actions
         */
    [SET_STUDENT_T_SHIRT_SIZE] (context, payload) {
      return new Promise((resolve, reject) => {
        const url = '/registrations/set-size'
        const args = {
          student_id: payload.student_id,
          size: payload.size,
          registration_id: payload.registration_id,
          registration_student_id: payload.registration_student_id
        }
        API.put(url, args)
          .then(result => {
            const data = result.data
            const status = result.status
            const statusMessage = result.statusText
            if (status !== 200) {
              const error = new Error(statusMessage)
              error.code = status
              reject(error)
            } else {
              context.commit(SET_STUDENT_T_SHIRT_SIZE, payload)
              resolve(data.result)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    [SET_STUDENT_IS_NAPPER] (context, payload) {
      return new Promise((resolve, reject) => {
        const url = '/registrations/set-napper'
        const args = {
          student_id: payload.student_id,
          is_napper: payload.is_napper,
          registration_id: payload.registration_id,
          registration_student_id: payload.registration_student_id
        }
        API.put(url, args)
          .then(result => {
            const data = result.data
            const status = result.status
            const statusMessage = result.statusText
            if (status !== 200) {
              const error = new Error(statusMessage)
              error.code = status
              reject(error)
            } else {
              context.commit(SET_STUDENT_T_SHIRT_SIZE, payload)
              resolve(data.result)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    /***********
         * Course and enrollment Actions
         */
    [UPDATE_REOPENED_COURSES] (context, payload) {
      console.log(UPDATE_REOPENED_COURSES, payload)
      return new Promise((resolve, reject) => {
        console.log(UPDATE_REOPENED_COURSES, payload)
        const slug = payload.program_slug
        const url = 'programs/' + slug + '/courses/open'
        console.log(UPDATE_REOPENED_COURSES, payload, slug, url)
        API.get(url)
          .then(result => {
            console.log(UPDATE_REOPENED_COURSES, result)
            const data = result.data
            const status = result.status
            const statusMessage = result.statusText
            if (status !== 200) {
              const error = new Error(statusMessage)
              error.code = status
              reject(error)
            } else {
              context.commit(UPDATE_REOPENED_COURSES, data.courses)
              resolve(data.result)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    /**
         *
         * @param context
         */
    [GET_ENROLLED_COURSES] (context) {
      context.commit(ENROLLED_COURSES_STATUS, 'loading')
      context.dispatch('wait/start', 'enrolled_courses.loading')
      console.log('context.getters.program.slug', context.getters.program)
      const programSlug = context.getters.program.slug
      const requestUrl = 'programs/' + programSlug + '/enrolled_courses/pending'
      const params = {
        year: context.getters.year
      }
      return new Promise((resolve, reject) => {
        API.get(requestUrl, params)
          .then(response => {
            const data = response.data
            console.log('action', GET_ENROLLED_COURSES, data)
            context.dispatch('wait/end', 'enrolled_courses.loading')
            if (typeof data.enrolled_courses !== 'undefined') {
              context.commit(UPDATE_ENROLLED_COURSES, data.enrolled_courses)
              context.commit(ENROLLED_COURSES_STATUS, 'success')
              resolve(response)
            } else {
              context.commit(ENROLLED_COURSES_STATUS, 'error')
              reject(response)
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'enrolled_courses.loading')
            context.commit(ENROLLED_COURSES_STATUS, 'error')
            reject(e)
          })
      })
    },
    [GET_ENROLLED_COURSES_COUNT] (context) {
      console.log(GET_ENROLLED_COURSES_COUNT)
      context.dispatch('wait/start', 'enrolled_courses.count')
      return new Promise((resolve, reject) => {
        getCartCount()
          .then(data => {
            context.dispatch('wait/end', 'enrolled_courses.count')
            if (typeof data.count !== 'undefined') {
              context.commit(UPDATE_ENROLLED_COURSES_COUNT, data.count)
              resolve(data)
            } else {
              reject(data)
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'enrolled_courses.count')
            reject(e)
          })
      })
    },
    [UPDATE_ENROLLED_COURSES] (context, payload) {
      console.log(GET_ENROLLED_COURSES)
      context.commit(ENROLLED_COURSES_STATUS, 'loading')
      context.commit(UPDATE_ENROLLED_COURSES, payload)
      debounceGetRegistration(context)
      const selectedSession = context.getters.selectedSession
      const selectedCalendar = context.getters.selectedCalendar
      if (!isEmpty(selectedSession) && selectedSession.id > 0) {
        debounceUpdateSession(context, selectedSession)
      }
      if (!isEmpty(selectedCalendar) && selectedCalendar.id > 0) {
        debounceUpdateCalendar(context, selectedCalendar)
      }
    },
    [UPDATE_ENROLLMENT] (context) {
      console.log('updateEnrollment')
      debounceGetRegistration(context)
      const selectedSession = context.getters.selectedSession
      console.log('updateEnrollment.session', selectedSession)
      if (!isNil(selectedSession) && !isEmpty(selectedSession)) {
        console.log('updateEnrollment.session')
        debounceUpdateSession(context, selectedSession)
      }
      const selectedCalendar = context.getters.selectedCalendar
      console.log('updateEnrollment.calendar', selectedCalendar)
      if (!isNil(selectedCalendar) && !isEmpty(selectedCalendar) && selectedCalendar.id !== '') {
        console.log('updateEnrollment.calendar')
        debounceUpdateCalendar(context, selectedCalendar)
      }
      const selectedStore = context.getters.selectedStore
      console.log('updateEnrollment.store', selectedStore)
      if (!isNil(selectedStore) && !isEmpty(selectedStore)) {
        console.log('updateEnrollment.session')
        debounceUpdateStore(context, selectedStore)
      }
    },
    [UPDATE_CART_MODIFIED] (context, registrationId = null) {
      context.dispatch('wait/start', 'cart-modified.get', { root: true })
      return new Promise((resolve, reject) => {
        getCartModified(registrationId)
          .then(data => {
            console.log(UPDATE_CART_MODIFIED, data)
            context.dispatch('wait/end', 'cart-modified.get', { root: true })
            if (data.modified === null) {
              context.commit(UPDATE_CART_MODIFIED, null)
              resolve(null)
            } else {
              const modified = parseISO(data.modified)
              console.log(UPDATE_CART_MODIFIED, data.modified, modified)
              context.commit(UPDATE_CART_MODIFIED, modified)
              resolve(modified)
            }
          })
          .catch(e => {
            context.dispatch('wait/end', 'cart-modified.get', { root: true })
            reject(e)
          })
      })
    }
  }
})

export default store
