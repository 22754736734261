import { Model } from '@vuex-orm/core'
import InformationCard from './InformationCard'
import Teacher from './Teacher'
import Member from './Member'
import MemberStudent from './MemberStudent'
import { isEmpty } from '@/utilities/utilities'
export default class Student extends Model {
  static entity = 'students';

  static fields () {
    return {
      id: this.attr(null),
      last: this.string('').nullable(),
      first: this.string('').nullable(),
      address: this.string('').nullable(),
      city: this.string('').nullable(),
      state: this.string('').nullable(),
      zip: this.string('').nullable(),
      phone: this.string('').nullable(),
      grade: this.string('').nullable(),
      parent_name: this.string('').nullable(),
      parent_number: this.string('').nullable(),
      em_name: this.string('').nullable(),
      em_number: this.string('').nullable(),
      note: this.string('').nullable(),
      school: this.string('').nullable(),
      teacher_id: this.attr(null),
      flStaffChild: this.boolean(false).nullable(),
      teacher: this.belongsTo(Teacher, 'teacher_id'),
      emergency_students: this.attr(null),
      guardians_students: this.attr(null),
      birthday: this.attr(null),
      is_napper: this.boolean(false),
      t_shirt_size: this.string(''),
      members: this.belongsToMany(
        Member,
        MemberStudent,
        'member_id',
        'student_id'
      ),
      enrollment_type: this.attr(null),
      information_cards: this.hasMany(InformationCard, 'student_id')
    }
  }

  get name () {
    return `${this.first} ${this.last}`
  }

  get isDeleted () {
    return !isEmpty(this.deleted)
  }
}
