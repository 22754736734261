import { Model } from '@vuex-orm/core'

export default class Grade extends Model {
  static entity = 'grades';

  static fields () {
    return {
      id: this.attr(null),
      grade: this.attr(null),
      slug: this.attr(null),
      type: this.attr(null),
      order: this.attr(null),
      active: this.boolean(true).nullable()
    }
  }
}
