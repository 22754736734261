import { Model } from '@vuex-orm/core'

export default class Email extends Model {
  static entity = 'emails';

  static fields () {
    return {
      id: this.attr(null),
      person_id: this.attr(null),
      address: this.attr(null),
      type: this.attr(null),
      is_primary: this.boolean(false)
    }
  }
}
