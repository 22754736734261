import { Model } from '@vuex-orm/core'

export default class Program extends Model {
  static entity = 'programs';
  static primaryKey = 'type';
  static fields () {
    return {
      id: this.attr(null),
      start: this.attr(null),
      end: this.attr(null),
      active: this.attr(null),
      registration_start: this.attr(null),
      registration_end: this.attr(null),
      next_year_registration_start: this.attr(null),
      next_year_registration_end: this.attr(null),
      name: this.attr(null),
      title: this.attr(null),
      slug: this.attr(null),
      sort: this.number(0),
      type: this.attr(null),
      brief: this.attr(null),
      icon_path: this.attr(null),
      badge_path: this.attr(null),
      registration_fee: this.attr(null),
      additional_registration_fee: this.attr(null),
      admin_name: this.attr(null),
      admin_email: this.attr(null),
      has_sessions: this.attr(null),
      has_calendars: this.attr(null),
      current_year: this.attr(null),
      require_enroll: this.boolean(false),
      track_napper: this.boolean(false),
      min_age: this.number(2.5),
      max_age: this.number(120),
      cutoff_month: this.number(9),
      cutoff_day: this.number(1),
      course_hold_tme: this.number(10)

    }
  }
}
