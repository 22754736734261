import pluralize from 'pluralize'

const uuid = () => {
  uniqueString()
}

const uniqueString = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}
const removeCountry = (address) => {
  const parts = address.split(',')
  if (parts[parts.length - 1] === 'USA' || parts[parts.length - 1] === 'CA') {
    parts.pop()
    return parts.join(',')
  } else { return address }
}
const titleCase = variable => {
  /* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  var wordSeparators = /([ :–—-])/

  return variable.split(wordSeparators)
    .map((current, index, array) => {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}

const slugify = string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const camelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase()
  }).replace(/\s+/g, '')
}

/**
 * will take a given string and pluralize it optionally including the count before the string
 * @param string
 * @param count
 * @param include (default: false)
 * @return {*}
 */
const plural = (string, count = 0, include = false) => {
  return pluralize(string, count, include)
}

const isNumeric = (value) => {
  return /^\d+$/.test(value)
}
/**
 * Formats a number as currency using the user's locale. 1234.56 => '$1,234.56'
 *
 * @param  {number} value      The number to be formatted
 * @param {boolean} showDecimal if you want the decimal part shown
 * @param  {string} currency The type of currency, such as 'USD' or 'JPY'
 * @param  {string} locale   The locale for formatting the number, such as 'en-US'. Defaults to navigator.language
 * @return {string}          The number as a string formatted in the locale of the user
 */

const currency = (value, showDecimal = false, currency = 'USD', locale = navigator.language) => {
  //const valueAsNumber = number(value, 0, 0)
  // return '$' + valueAsNumber
  // don't actually use currency per request
  const fractionDigits = showDecimal ? 2 : 0
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits
  })
  return formatter.format(value)
}

/**
 * Formats a number to a culture code locale. 1234 => '1,234'
 *
 * @param  {number} num    The number to be formatted
 * @param {number} minimumFractionDigits how many decimals
 * @param {number} maximumFractionDigits how many decimals
 * @param  {string} locale Culture-code for formatting the number, such as 'en-US'. Defaults to navigator.language
 * @return {string}        A formatted number based on a culture code
 */
const number = (num,
  minimumFractionDigits = 0,
  maximumFractionDigits = 1,
  locale = navigator.language) => {
  if (typeof num !== 'number') {
    num = parseFloat(num)
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }).format(num)
}

/**
 * Replaces all characters of a string that surpass a length with a new string.
 *
 * @param  {string} str    String of text to be truncated
 * @param  {number} length Amount of characters to allow before truncating. Defaults to 100
 * @param  {string} append Text to append to the end of a truncated string. Defaults to '...'
 * @return {string}        The original string, or a truncated version if it exceed the allowed limit.
 */
const truncate = (str, length = 100, append = '...') => {
  // TODO: dont split last word
  if (str.length > length) {
    return str.substring(0, length - append.length) + append
  } else {
    return str
  }
}

const translateText = (string) => {
  var translateText = {
    Color: 'Colour',
    Behavior: 'Behaviour',
    Neighborhood: 'Neighbourhood',
    Paycheck: 'Paycheque'
  }
  let newString = string
  // if country code in global then
  for (const american in translateText) {
    const canadianBritish = translateText[american]
    newString = newString.replace(new RegExp(american, 'ig'), canadianBritish)
  }
  return newString
}

/**
 * Takes an array and converts to a readable string
 * Example: ['a','b','c'] -> 'a, b, and c'
 *          ['a','b'] -> 'a and b'
 *          ['a'] -> 'a'
 *          [] -> ''
 *
 * @param {Array} array
 * @return {String}
 */
const toList = (array) => {
  const newArray = array.slice()
  let toList = ''
  if (typeof newArray !== 'undefined' && Array.isArray(newArray)) {
    if (newArray.length === 1) {
      toList = newArray[0]
    } else if (newArray.length === 2) {
      toList = newArray.shift() + ' and ' + newArray.shift()
    } else if (newArray.length > 2) {
      const last = newArray.pop()
      toList = newArray.join(', ')
      toList = toList + ' and ' + last
    } else {
      return ''
    }
  }
  return toList
}

/**
 * Formats a phone number.
 * Example: 123-456-7890 => (123) 456-7890
 *
 * @param {String} phone
 * @return {Void}
 */
const phone = (phone) => {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

const niceBoolean = (value, isTrue = 'Yes', isFalse = 'No') => {
  return value ? isTrue : isFalse
}

export {
  titleCase, slugify, camelCase, plural, isNumeric, currency, translateText,
  toList, phone, niceBoolean,
  uniqueString,
  uuid, number, truncate, removeCountry
}
