import { Model } from '@vuex-orm/core'
import Medical from './Medical'

export default class InformationCardMedical extends Model {
  static entity = 'informationCardMedicals';

  static fields () {
    return {
      id: this.attr(null),
      medical_id: this.attr(null),
      information_card_id: this.attr(null),
      information_cards_medical_type: this.attr(null),
      medical: this.belongsTo(Medical, 'medical_id')
    }
  }

  get medicalType () {
    return this.information_cards_medical_type.name
  }

  static medicalTypes () {
    return [
      { key: 1, value: 'Allergy' },
      { key: 2, value: 'Infection' },
      { key: 3, value: 'Convulsions' },
      { key: 4, value: 'Physical Condition' }
    ]
  }
}
