import { isEmpty } from '@/utilities/utilities'

/**
 * look to see if window.__INITIAL_STATE__ exists and return it otherwise return empty object
 * @returns {{}|*}
 */
const InitialState = () => {
  if (typeof window.__INITIAL_STATE__ !== 'undefined') {
    return window.__INITIAL_STATE__
  } else {
    return {}
  }
}

const disableFromEvent = event => {
  if (typeof event !== 'undefined' && event.target !== 'undefined') {
    event.target.disabled = true
    event.target.classList.add('disabled')
  }
}

const enableFromEvent = event => {
  if (typeof event !== 'undefined' && event.target !== 'undefined') {
    event.target.disabled = false
    event.target.classList.remove('disabled')
  }
}

const toBoolean = value => {
  if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
      case 'yes':
      case 'on':
      case '1':
        return true
      default:
        return false
    }
  }
  return value
}

const badgeForSpace = (course) => {
  const className = classForSpace(course, 'badge-')
  if (className === '') {
    return '<!-- space available -->'
  }
  if (course.space > 0) {
    return ' <span class="badge ' + className + ' pl-1">Filling</span>'
  } else {
    return '<span class="badge badge-danger pl-1">Full</span>'
  }
}

/**
 *
 * @param course
 * @param prefix
 * @returns {string}
 */
const classForSpace = (course, prefix = '') => {
  const space = parseInt(course.space)
  if (course.space_warning_ct === 0 && course.space_danger_ct === 0) {
    return ''
  } else {
    if (course.space_warning_ct > 0) {
      if (space > course.space_warning_ct) {
        return ''
      } else if (course.space_danger_ct > 0) {
        if (space > course.space_danger_ct) {
          return prefix + 'warning'
        } else {
          return prefix + 'danger'
        }
      }
    } else if (course.space_danger_ct > 0) {
      if (space > course.space_danger_ct) {
        return ''
      } else {
        return prefix + 'danger'
      }
    } else {
      return ''
    }
  }
}

/**
 * remaps school abbreviation to a display abbreviation (mainly for vvp -> vvelc for now)
 * @param schoolAbbreviation
 * @returns {*|string}
 */
const displayAbbreviation = (schoolAbbreviation) => {
  if (!isEmpty(schoolAbbreviation) && schoolAbbreviation.toUpperCase() === 'VVP') {
    return 'VVELC'
  }
  return schoolAbbreviation
}

export { InitialState, disableFromEvent, enableFromEvent, toBoolean, badgeForSpace, classForSpace, displayAbbreviation }
