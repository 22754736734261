export const UPDATE_YEAR = 'updateYear'
export const UPDATE_PROGRAM = 'updateProgram'
export const UPDATE_PROGRAMS = 'updatePrograms'
export const UPDATE_REGISTRATION_MEMBER = 'updateRegistrationMember'

export const GET_CURRENT_REGISTRATION = 'getCurrentRegistration'
export const CLEAR_REGISTRATION = 'clearRegistration'
export const ADD_TO_REGISTRATION_BALANCE = 'addToBalance'
export const SET_REGISTRATION = 'setRegistration'
export const SET_REGISTRATION_ID = 'setRegistrationId'
export const REGISTRATION_STATUS = 'registrationStatus'
export const UPDATE_REGISTRATIONS = 'updateRegistrations'
export const UPDATE_REGISTRATION = 'updateRegistration'
export const UPDATE_SESSIONS = 'updateSessions'
export const FILLING_SESSIONS = 'fillingSessions'
export const UPDATE_SESSION = 'updateSession'
export const UPDATE_STORE = 'updateStore'
export const SESSION_STATUS = 'sessionStatus'
export const STORE_STATUS = 'storeStatus'
export const SESSIONS_STATUS = 'sessionsStatus'
export const UPDATE_STATES = 'updateStates'
export const UPDATE_WAITLISTS = 'updateWaitlists'
export const UPDATE_CALENDARS = 'updateCalendars'
export const UPDATE_CALENDAR = 'updateCalendar'
export const GET_ENROLLED_COURSES = 'getEnrolledCourses'
export const GET_ENROLLED_COURSES_COUNT = 'getEnrolledCoursesCount'
export const CLEAR_ENROLLED_COURSES = 'clearEnrolledCourses'
export const UPDATE_ENROLLED_COURSES = 'updateEnrolledCourses'
export const UPDATE_ENROLLED_COURSES_COUNT = 'updateEnrolledCoursesCount'
export const ENROLLED_COURSES_STATUS = 'enrolledCoursesStatus'
export const UPDATE_ENROLLMENT = 'updateEnrollment'

export const SET_STUDENT_T_SHIRT_SIZE = 'setStudentTShirtSize'
export const SET_STUDENT_IS_NAPPER = 'setStudentIsNapper'
export const UPDATE_MEMBER = 'updateMember'
export const MEMBER_STATUS = 'memberStatus'

export const SHOW_UNPAID = 'showUnpaid'
export const FORCE_PAY_UNPAID = 'forcePayUnpaid'
export const ALLOW_OVER_ENROLL = 'allowOverEnroll'
export const HAS_UNPAID_REGISTRATIONS = 'hasUnpaidRegistrations'
export const UPDATE_CART_MODIFIED = 'updateCartModified'

export const UPDATE_REOPENED_COURSES = 'updateReopenedCourses'

export const START_UPDATING = 'startUpdating'
export const END_UPDATING = 'endUpdating'
