import { Model } from '@vuex-orm/core'
export default class Relationship extends Model {
  static entity = 'relationships';

  static fields () {
    return {
      id: this.attr(null),
      title: this.string('').nullable(),
      abbreviation: this.string('').nullable()
    }
  }
}
