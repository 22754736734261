import { library } from '@fortawesome/fontawesome-svg-core'
import { faAllergies } from '@fortawesome/pro-solid-svg-icons/faAllergies'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck' // Accept
import { faBallot } from '@fortawesome/pro-solid-svg-icons/faBallot'
import { faBoxBallot } from '@fortawesome/pro-solid-svg-icons/faBoxBallot'
import { faBallotCheck } from '@fortawesome/pro-solid-svg-icons/faBallotCheck'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan' // Reject & Cancel
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faBookReader } from '@fortawesome/pro-solid-svg-icons/faBookReader' // Student
import { faBoxUsd } from '@fortawesome/pro-solid-svg-icons/faBoxUsd' // Registrations
import { faBusinessTime } from '@fortawesome/pro-solid-svg-icons/faBusinessTime' // Waitlist
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar' // Schedule & schedules
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt' // Sessions
import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus' // AddCalendar
import { faCampground } from '@fortawesome/pro-solid-svg-icons/faCampground'
import { faCartArrowDown } from '@fortawesome/pro-solid-svg-icons/faCartArrowDown' // AddCalendar
import { faCartPlus } from '@fortawesome/pro-solid-svg-icons/faCartPlus' // AddCart
import { faChalkboardTeacher } from '@fortawesome/pro-solid-svg-icons/faChalkboardTeacher' // Courses & KinderPlus & Teacher
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faChild } from '@fortawesome/pro-solid-svg-icons/faChild' // Kinder plus
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle' // validated
import { faClipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard'
import { faClipboardCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardCheck'
import { faCodeMerge } from '@fortawesome/pro-solid-svg-icons/faCodeMerge' // Merge
import { faCogs } from '@fortawesome/pro-solid-svg-icons/faCogs'
import { faCreditCardFront } from '@fortawesome/pro-solid-svg-icons/faCreditCardFront' // Pay
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload' // Export
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEraser } from '@fortawesome/pro-solid-svg-icons/faEraser' // Drop, clear
import { faExclamationSquare } from '@fortawesome/pro-solid-svg-icons/faExclamationSquare' // Alert
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck' // Select
import { faFileContract } from '@fortawesome/pro-solid-svg-icons/faFileContract' // WaiverText
// eslint-disable-next-line import/no-duplicates
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons' // Excel
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar' // Fee & Registration
import { faFileMinus } from '@fortawesome/pro-solid-svg-icons/faFileMinus' // DeleteFile
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf' // Pdf
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus' // AddFile
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature' // Waivers
import { faFileSpreadsheet } from '@fortawesome/pro-solid-svg-icons/faFileSpreadsheet' // Csv
import { faHandHoldingSeedling } from '@fortawesome/pro-solid-svg-icons/faHandHoldingSeedling' // Care
import { faHandHeart } from '@fortawesome/pro-solid-svg-icons/faHandHeart'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake' // Discounts
import { faHandsUsd } from '@fortawesome/pro-solid-svg-icons/faHandsUsd' // Payments
import { faHouseDay } from '@fortawesome/pro-solid-svg-icons/faHouseDay'
import { faHourglassEnd } from '@fortawesome/pro-solid-svg-icons/faHourglassEnd' // EndTime
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons/faHourglassHalf' // FullTime
import { faHourglassStart } from '@fortawesome/pro-solid-svg-icons/faHourglassStart' // StartTime
import { faIdCard } from '@fortawesome/pro-solid-svg-icons/faIdCard' // InformationCard & InformationCards
import { faInboxIn } from '@fortawesome/pro-solid-svg-icons/faInboxIn' // Save
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons/faInfoSquare'
import { faKeyboard } from '@fortawesome/pro-solid-svg-icons/faKeyboard'
import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons/faLayerPlus' // AddCourse
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faList } from '@fortawesome/pro-solid-svg-icons/faList' // Accounts & Account
import {
  faMinusSquare,
  faNotesMedical,
  faObjectGroup,
  faPaperPlane,
  faPen,
  faPhone,
  faPrint,
  faRoute,
  faSchool,
  faSearch,
  faSeedling,
  faShoppingCart,
  faSignIn,
  faSignOut,
  faStore,
  faSun,
  faSync,
  faSyncAlt,
  faTvRetro,
  faUndo,
  faUpload,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUserSecret,
  faUserShield,
  faMoneyCheck
} from '@fortawesome/pro-solid-svg-icons' // BlockGroup & CourseBlock &CourseBulkGroup // SendEmail // Signature // Outdoor // School & Schools & Enrichment // Excel // Vvelc // Cart // Login // Logout // Summer // Reset // Reset // Programs // undo // Import // Member // Waitlists // SiteUser // Students // Unenroll // AddStudent & Enroll // Members // Teachers // AdminUser // ProgramUser

import { faShoppingCart as falShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart' // Cart

import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { faTimesHexagon } from '@fortawesome/pro-regular-svg-icons/faTimesHexagon'

library.add(
  faAllergies,
  faBadgeCheck,
  faBallot,
  faBallotCheck,
  faBan,
  faBars,
  faBookReader,
  faBoxBallot,
  faBoxUsd,
  faBusinessTime,
  faCalendar,
  faCalendarAlt,
  faCalendarPlus,
  faCartPlus,
  faCartArrowDown,
  faChalkboardTeacher,
  faCampground,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCheckCircle,
  faClipboard,
  faClipboardCheck,
  faCodeMerge,
  faCogs,
  faCreditCardFront,
  faDownload,
  faEdit,
  faEnvelope,
  faEraser,
  faExclamationSquare,
  faEye,
  faFileCheck,
  faFileContract,
  faFileExcel,
  faFileInvoiceDollar,
  faFileMinus,
  faFilePdf,
  faFilePlus,
  faFileSignature,
  faFileSpreadsheet,
  faHandHeart,
  faHandHoldingSeedling,
  faHandshake,
  faHandsUsd,
  faHouseDay,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faIdCard,
  faInboxIn,
  faInfo,
  faInfoCircle,
  faInfoSquare,
  faKeyboard,
  faLayerPlus,
  faLink,
  faList,
  faMinusSquare,
  faMoneyCheck,
  faNotesMedical,
  faObjectGroup,
  faPaperPlane,
  faPen,
  faPhone,
  faPrint,
  faRoute,
  faSchool,
  faSearch,
  faSeedling,
  faShoppingCart,
  falShoppingCart,
  faSignIn,
  faSignOut,
  faSpinner,
  faStore,
  faSun,
  faSync,
  faSyncAlt,
  faTvRetro,
  faTimesHexagon,
  faUndo,
  faUpload,
  faUser,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUserSecret,
  faUserShield
)
