import { Model } from '@vuex-orm/core'
import Member from './Member'
export default class School extends Model {
  static entity = 'schools';

  static fields () {
    return {
      id: this.attr(null),
      abbreviation: this.attr(null),
      name: this.attr(null),
      long_name: this.attr(null),
      start_grade: this.attr(null),
      end_grade: this.attr(null),
      care_manager_email: this.attr(null),
      site_email: this.attr(null),
      display: this.attr(null),
      members: this.hasMany(Member, 'site_id')
    }
  }
}
