import Vue from 'vue'
import App from './App'
import store from './store'
import './utilities/fontawesome.js'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import Multiselect from 'vue-multiselect'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

import VueWait from 'vue-wait' // add VueWait as Vue plugin
import IdleVue from 'idle-vue'
import Notifications from 'vue-notification'
import responsive from 'vue-responsive'
import PortalVue from 'portal-vue'
import VModal from 'vue-js-modal'
// import LogRocket from 'logrocket';
// LogRocket.init('ju1hyy/cfsd16');
import * as Sentry from '@sentry/vue'
import VueScrollTo from 'vue-scrollto'

// FIXME(scd) to evaluate
import Tooltip from 'vue-directive-tooltip'

import VueProgressBar from 'vue-progressbar'
import Nl2br from 'vue-nl2br'

// FIXME(scd) dev only
import JSONView from 'vue-json-component'

if (window.cfEventBus === undefined) {
  window.cfEventBus = new Vue()
}
// import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  Vue,
  dsn: 'https://5e4960f16df34a27853fa94e808f4099@o396293.ingest.sentry.io/6477087',
  environment: process.env.MIX_RELEASE_STAGE,
  release: process.env.MIX_LAST_TAG
})
const moment = extendMoment(Moment)
moment.tz.setDefault('America/Phoenix')
Vue.use(VueWait)
Vue.use(Notifications)
Vue.use(responsive)
Vue.use(PortalVue)
Vue.use(VModal)
Vue.use(Tooltip)
Vue.use(VueProgressBar, {
  color: 'rgb(12,81,255)',
  failedColor: 'red',
  thickness: '6px'
})
Vue.use(VueScrollTo, {
  offset: -100
})
Vue.component('nl2br', Nl2br)
Vue.use(JSONView)
Vue.use(IdleVue, {
  idleTime: 5000, // 1 minutes
  startAtIdle: false,
  store: store
})
Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.component(
  'cs-button',
  // The `import` function returns a Promise.
  () =>
    import(/* webpackChunkName: "CsButton" */ './components/controls/CsButton')
)
Vue.component(
  'cs-icon',
  // The `import` function returns a Promise.
  () => import(/* webpackChunkName: "CsIcon" */ './components/controls/CsIcon')
)
Vue.component(
  'cs-loading',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CsLoading" */ './components/controls/CsLoading'
    )
)

Vue.component(
  'cs-pdf-button',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CsPdfButton" */ './components/controls/CsPdfButton'
    )
)

Vue.component(
  'cs-disclose',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CsDisclose" */ './components/controls/CsDisclose'
    )
)
Vue.component(
  'cs-form-group',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "FormGroup" */ './components/controls/FormGroup'
    )
)
Vue.component(
  'copiable-text',
  // The `import` function returns a Promise.
  () =>
    import(/* webpackChunkName: "CopiableText" */ './components/controls/CopiableText')
)

Vue.component(
  'member-header',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "MemberHeader" */ './components/header/MemberHeader.vue'
    )
)
Vue.component(
  'shopping-cart',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "ShoppingCart" */ './components/cart/ShoppingCart'
    )
)
Vue.component(
  'students-table',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "StudentsTable" */ './components/students_table/StudentsTable'
    )
)
Vue.component(
  'course-search',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CourseSearch" */ './components/course_search/CourseSearch.vue'
    )
)

Vue.component(
  'course-detail',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CourseDetail" */ './components/shared/Course'
    )
)

Vue.component(
  't-shirts-portal',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "TShirtPortal" */ './components/t_shirts/TShirtsPortal'
    )
)
Vue.component(
  'waivers',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "StudentsTable" */ './components/registrations/Waivers'
    )
)

Vue.component(
  'enrollment-error-portal',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "EnrollmentErrorPortal" */ './components/enrollments/EnrollmentsErrorPortal'
    )
)

Vue.component(
  'information-card',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "InformationCard" */ './components/information_card/InformationCard'
    )
)

Vue.component(
  'login-portal',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "LoginPortal" */ './components/members/LoginPortal'
    )
)

/***********
 * Program
 */
Vue.component(
  'program-summary',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "ProgramSummary" */ './components/programs/ProgramSummary'
    )
)

/***********
 * Registration
 */
Vue.component(
  'registration-portal',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "RegistrationPortal" */ './components/registrations/RegistrationPortal'
    )
)
Vue.component(
  'unpaid-alert',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "UnpaidAlert" */ './components/registrations/UnpaidAlert'
    )
)

/***********
 * Calendars and Sessions
 */
Vue.component(
  'calendar-session-portal',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "CalendarSessionPortal" */ './components/calendars_sessions/CalendarSessionPortal'
    )
)

Vue.component(
  'admin-sidebar',
  // The `import` function returns a Promise.
  () =>
    import(
      /* webpackChunkName: "AdminSidebar" */ './components/sidebar/AdminSidebar'
    )
)
Vue.config.productionTip = false

new Vue({
  store,
  wait: new VueWait({
    useVuex: true // You must pass this option `true` to use Vuex
  }),
  render: h => h(App)
}).$mount('#vue_public')
