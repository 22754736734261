import { Model } from '@vuex-orm/core'
export default class Teacher extends Model {
  static entity = 'teachers';

  static fields () {
    return {
      id: this.attr(null),
      email: this.attr(null),
      name: this.attr(null),
      first: this.attr(null),
      last: this.attr(null),
      biography: this.attr(null),
      school: this.attr(null),
      grade: this.attr(null),
      site_id: this.attr(null),
      type: this.attr(null),
      role: this.attr(null),
      label: this.attr(null)
    }
  }

  get summary () {
    return `${this.first} ${this.last} (${this.school})`
  }
}
