import { Model } from '@vuex-orm/core'
import Session from './Session'
import Calendar from './Calendar'
export default class School extends Model {
  static entity = 'courses';

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      grades: this.attr(null),
      site: this.attr(null),
      start_time: this.attr(null),
      end_time: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      fee: this.attr(null),
      material_fee: this.attr(null),
      teacher: this.attr(null),
      maxStudents: this.number(null),
      space: this.number(null),
      waitlist: this.attr(null),
      brief: this.attr(null),
      session_id: this.attr(null),
      calendar_id: this.attr(null),
      template_id: this.attr(null),
      image: this.attr(null),
      session: this.belongsTo(Session, 'session_id'),
      calendar: this.belongsTo(Calendar, 'calendar_id')
    }
  }
}
