import { Model } from '@vuex-orm/core'
import InformationCard from './InformationCard'
import Student from './Student'
import Registration from './Registration'
import MemberStudent from './MemberStudent'
export default class Member extends Model {
  static entity = 'members';

  static fields () {
    return {
      id: this.attr(null),
      username: this.string('').nullable(),
      email: this.string('').nullable(),
      full_name: this.string('').nullable(),
      first: this.string('').nullable(),
      last: this.string('').nullable(),
      active: this.boolean(true).nullable(),
      note: this.string('').nullable(),
      last_login: this.attr(null),
      last_activity: this.attr(null),
      role_id: this.number(1).nullable(),
      role: this.string('parent'),
      students: this.belongsToMany(
        Student,
        MemberStudent,
        'member_id',
        'student_id'
      ),
      information_cards: this.hasMany(InformationCard, 'member_id'),
      registrations: this.hasMany(Registration, 'member_id')
    }
  }

  get name () {
    return `${this.first} ${this.last}`
  }
}
