import { Model } from '@vuex-orm/core'
import InformationCardMedical from './InformationCardMedical'
import Student from './Student'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
export default class Medical extends Model {
  static entity = 'medicals';

  static fields () {
    return {
      id: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      the_condition: this.string('').nullable(),
      description: this.string('').nullable(),
      instructions: this.string('').nullable(),
      medicines: this.string('').nullable(),
      food: this.boolean(false).nullable(),
      type: this.string('').nullable(),
      student: this.belongsTo(Student, 'student_id'),
      information_card_medical: this.hasOne(
        InformationCardMedical,
        'medical_id'
      )
    }
  }

  static validations () {
    return {
      medical: {
        the_condition: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2)
        },
        description: {
          maxLength: maxLength(200)
        },
        instructions: {
          maxLength: maxLength(200)
        },
        medicines: {
          maxLength: maxLength(50)
        },
        type: {
          required
        }
      }
    }
  }

  static types () {
    return [
      { key: 1, value: 'Allergy' },
      { key: 2, value: 'Infection' },
      { key: 3, value: 'Convulsions' },
      { key: 4, value: 'Physical Condition' }
    ]
  }

  static enums () {
    return [
      { key: 1, value: 'allergy' },
      { key: 2, value: 'infection' },
      { key: 3, value: 'convulsions' },
      { key: 4, value: 'physical_condition' }
    ]
  }
}
