import { Model } from '@vuex-orm/core'
import Student from './Student'
import Registration from './Registration'

export default class RegistrationStudent extends Model {
  static entity = 'registrationStudents';

  static fields () {
    return {
      id: this.attr(null),
      student_id: this.attr(null),
      registration_id: this.attr(null),
      student: this.belongsTo(Student, 'student_id'),
      registration: this.belongsTo(Registration, 'registration_id')
    }
  }
}
