import { Database } from '@vuex-orm/core'

import Grade from '../models/Grade'

import InformationCard from '../models/InformationCard'
import InformationCardMedical from '../models/InformationCardMedical'
import Medical from '../models/Medical'
import Member from '../models/Member'
import Relationship from '../models/Relationship'
import MemberStudent from '../models/MemberStudent'
import School from '../models/School'
import Student from '../models/Student'
import Teacher from '../models/Teacher'
import Program from '../models/Program'
import Contact from '../models/Contact'
import Phone from '../models/Phone'
import Email from '../models/Email'
import Address from '../models/Address'
import InformationCardContact from '../models/InformationCardContact'
import Signature from '../models/Signature'
import Registration from '../models/Registration'
import RegistrationStudent from '../models/RegistrationStudent'
import Course from '../models/Course'
import Session from '../models/Session'
import Calendar from '../models/Calendar'
const database = new Database()
database.register(Grade)
database.register(InformationCard)
database.register(InformationCardMedical)
database.register(Medical)
database.register(Member)
database.register(MemberStudent)
database.register(Relationship)
database.register(School)
database.register(Student)
database.register(Teacher)
database.register(Program)
database.register(Contact)
database.register(Email)
database.register(Phone)
database.register(Address)
database.register(InformationCardContact)
database.register(Signature)
database.register(Registration)
database.register(RegistrationStudent)
database.register(Course)
database.register(Session)
database.register(Calendar)

export default database
