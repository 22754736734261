import { Model } from '@vuex-orm/core'
import Member from './Member'
import InformationCard from './InformationCard'

export default class Signature extends Model {
  static entity = 'signatures';

  static fields () {
    return {
      id: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      member_id: this.attr(null),
      information_card_id: this.attr(null),
      signature: this.attr(null),
      dir: this.attr(null),
      size: this.attr(null),
      mime_type: this.attr(null),
      path: this.attr(null),
      member: this.belongsTo(Member, 'member_id'),
      information_card: this.belongsTo(InformationCard, 'information_card_id')
    }
  }
}
