import { Model } from '@vuex-orm/core'

import Member from './Member'
import Student from './Student'
import School from './School'
import Teacher from './Teacher'
import InformationCardMedical from './InformationCardMedical'
import InformationCardContact from './InformationCardContact'
import {
  maxLength,
  minLength,
  minValue,
  maxValue,
  required,
  requiredUnless,
  email
} from 'vuelidate/lib/validators'
import moment from 'moment'
import Signature from './Signature'
import isEmpty from 'lodash/isEmpty'

const mustBeTrue = value => value === true

export default class InformationCard extends Model {
  static entity = 'informationCards';

  static fields () {
    return {
      id: this.attr(null),
      school_year: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      completed: this.attr(null),
      signed: this.attr(null),
      immunizations_completed: this.attr(null),
      immunizations_completed_by: this.attr(null),
      cic_completed: this.attr(null),
      cic_completed_by: this.attr(null),
      enrolled: this.attr(null),
      disenrolled: this.attr(null),
      student_updated: this.attr(null),
      date_of_birth: this.attr(null),
      created_by: this.attr(null),
      modified_by: this.attr(null),
      completed_by: this.attr(null),
      signed_by: this.attr(null),
      member_id: this.attr(0),
      student_id: this.attr(null),
      registration_id: this.attr(null),
      student_first: this.attr(null),
      student_last: this.attr(null),
      gender: this.attr(null),
      grade: this.attr(null),
      school: this.attr(null),
      teacher_id: this.attr(null),
      enrollment_type: this.attr(null),
      address: this.attr(null),
      city: this.attr('Tucson'),
      state: this.attr('AZ'),
      zip: this.attr(null),
      home_phone: this.attr(null),
      home: this.attr(null),
      medical_complete: this.attr(null),
      mother_guardian_first: this.attr(null),
      mother_guardian_last: this.attr(null),
      mother_guardian_address: this.attr(null),
      mother_guardian_city: this.attr('Tucson'),
      mother_guardian_state: this.attr('AZ'),
      mother_guardian_zip: this.attr(null),
      mother_guardian_phone: this.attr(null),
      mother_guardian_cell: this.attr(null),
      mother_guardian_email: this.attr(null),
      mother_guardian_is_employee: this.boolean(false),
      father_guardian_first: this.attr(null),
      father_guardian_last: this.attr(null),
      father_guardian_address: this.attr(null),
      father_guardian_city: this.attr('Tucson'),
      father_guardian_state: this.attr('AZ'),
      father_guardian_zip: this.attr(null),
      father_guardian_phone: this.attr(null),
      father_guardian_cell: this.attr(null),
      father_guardian_email: this.attr(null),
      father_guardian_is_employee: this.attr(false),
      health_care_provider: this.attr(null),
      health_care_provider_phone: this.attr(null),
      insurance_carrier: this.attr(null),
      not_to_remove: this.attr(null),
      custody_papers_filed: this.boolean(false).nullable(),
      telephone_authorization_code: this.attr(null),
      additional_comments: this.attr(null),
      signature_data: this.attr(null),
      special_instructions: this.attr(null),
      submitted: this.attr(null),
      individual_called: this.attr(null),
      has_iep: this.boolean(null).nullable(),
      has_504: this.boolean(null).nullable(),
      medical_summary: this.attr(null),
      member: this.belongsTo(Member, 'member_id'),
      student: this.belongsTo(Student, 'student_id'),
      teacher: this.belongsTo(Teacher, 'teacher_id'),
      site: this.belongsTo(School, 'grade', 'abbreviation'),
      photo_release: this.boolean(true),
      information_card_medicals: this.hasMany(
        InformationCardMedical,
        'information_card_id'
      ),
      information_card_contacts: this.hasMany(
        InformationCardContact,
        'information_card_id'
      ),
      signatures: this.hasMany(Signature, 'information_card_id')
    }
  }

  static validations () {
    const date = new Date()
    let schoolYear = 1 + date.getFullYear()
    console.log('schoolYear', schoolYear)
    console.log('selectedPropery', this.program)
    if (
      !isEmpty(this.information_card) &&
      'school_year' in this.information_card &&
      this.information_card.school_year > 0
    ) {
      console.log('school_year', this.information_card.school_year)
      schoolYear = this.information_card.school_year
    }
    console.log('schoolYear2', schoolYear)

    const cutoffMonth = !isEmpty(this.program) ? this.program.cutoff_month - 1 : 8
    const cutoffDay = !isEmpty(this.program) ? this.program.cutoff_day : 1
    const minAge = !isEmpty(this.program) ? this.program.min_age : 2.6
    const maxDate = moment().year(schoolYear).month(cutoffMonth).date(cutoffDay).subtract(minAge, 'years').endOf('day')

    const maxAge = this.hasProgram ? this.program.max_age : 120
    const minDate = moment().year(schoolYear).month(cutoffMonth).date(cutoffDay).subtract(maxAge, 'years').startOf('day')
    console.log('maxDate', maxDate, 'minDate', minDate)
    return {
      birthdayAsDate: {
        required: true
        // maxValue: maxValue(maxDate),
        // minValue: minValue(minDate)
      },
      information_card: {
        student_first: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        student_last: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        gender: {
          required
        },
        date_of_birth: {
          required
        },
        enrollment_type: {
          required
        },
        grade: {
          required
        },
        school: {
          required
        },
        teacher: {},
        birthdayAsDate: {
          required

        },
        address: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        city: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        state: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(2)
        },
        zip: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(5)
        },
        home_phone: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(7)
        },
        mother_guardian_first: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        mother_guardian_last: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        mother_guardian_address: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        mother_guardian_city: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        mother_guardian_state: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(2)
        },
        mother_guardian_zip: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(5)
        },
        mother_guardian_phone: {
          required: requiredUnless('mother_guardian_cell'),
          maxLength: maxLength(32),
          minLength: minLength(7)
        },
        mother_guardian_cell: {
          required: requiredUnless('mother_guardian_phone'),
          maxLength: maxLength(32),
          minLength: minLength(7)
        },
        mother_guardian_email: {
          email
        },
        father_guardian_first: {
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        father_guardian_last: {
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        father_guardian_address: {
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        father_guardian_city: {
          required,
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        father_guardian_state: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(2)
        },
        father_guardian_zip: {
          maxLength: maxLength(32),
          minLength: minLength(5)
        },
        father_guardian_phone: {
          maxLength: maxLength(32),
          minLength: minLength(7)
        },
        father_guardian_cell: {
          maxLength: maxLength(32),
          minLength: minLength(7)
        },
        father_guardian_email: {
          email
        },
        home: {
          required
        },
        medical_complete: {
          required,
          mustBeTrue
        },
        has_iep: {
          required
        },
        has_504: {
          required
        },
        additional_comments: {
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        special_instructions: {
          maxLength: maxLength(252),
          minLength: minLength(2)
        },
        health_care_provider: {
          maxLength: maxLength(252),
          minLength: minLength(3),
          required
        },
        health_care_provider_phone: {
          maxLength: maxLength(32),
          minLength: minLength(7),
          required
        },
        insurance_carrier: {
          maxLength: maxLength(252),
          minLength: minLength(3),
          required
        },
        not_to_remove: { maxLength: maxLength(252), minLength: minLength(7) },
        custody_papers_filed: {
          required
        },
        telephone_authorization_code: {
          maxLength: maxLength(32),
          minLength: minLength(4)
        },
        individual_called: {
          required,
          maxLength: maxLength(32),
          minLength: minLength(4)
        },
        signature_data: {
          required
        },
        photo_release: {}
      }
    }
  }

  get name () {
    return `${this.student_first} ${this.student_last}`
  }

  get birthdayAsDate () {
    let birthday = this.date_of_birth
    if (birthday === null) {
      return null
    } else {
      // stored without timezone offset so fix it
      birthday = birthday.replace('+00:00', '-07:00')
      return new Date(birthday)
    }
  }

  get birthdayAsString () {
    const birthday = this.birthdayAsDate
    if (birthday === null) {
      return null
    } else {
      return birthday.toLocaleDateString()
    }
  }
}
