<template>
  <span v-show="!isProduction" class="fixed-bottom">
    <span
      class=" badge"
      :class="{
        'badge-info': isProduction,
        'badge-primary': !isProduction
      }"
    >
      Release: {{ release }}
    </span>
  </span>
</template>

<script>
import { InitialState } from '@/utilities/cs-utilities'

export default {
  name: 'ReleaseBadge',
  data () {
    return {}
  },
  computed: {
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    release () {
      const initialState = InitialState()
      if (typeof initialState.SITE_VERSION !== 'undefined') {
        return initialState.SITE_VERSION
      } else {
        return 'unset'
      }
    }
  }
}
</script>

<style scoped></style>
