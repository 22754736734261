<template>
  <div id="app">
    <mounting-portal
        mount-to="#course_search_portal"
        name="course_search">
      <course-search/>
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('member_header_portal')"
        mount-to="#member_header_portal"
        name="member_header">
      <member-header/>
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('course_detail_portal')"
        mount-to="#course_detail_portal"
        name="course_detail">
      <course-detail :show-details="true" />
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('shopping_cart_portal')"
        mount-to="#shopping_cart_portal"
        name="shopping_cart">
      <shopping-cart />
    </mounting-portal>
    <mounting-portal
      v-if="hasPortal('students_table_portal')"
      mount-to="#students_table_portal"
      name="students_table">
      <students-table :initial-data="portals.students_table_portal" />
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('t_shirts_portal')"
        mount-to="#t_shirts_portal"
        name="t_shirts_portal">
      <t-shirts-portal v-bind="portalData('t_shirts_portal')"/>
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('enrollments_error_portal')"
        mount-to="#enrollments_error_portal"
        name="enrollments_error_portal">
      <enrollment-error-portal v-bind="portalData('enrollments_error_portal')"/>
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('information_card_portal')"
        mount-to="#information_card_portal"
        name="information_card">
      <information-card v-bind="portalData('information_card_portal')"/>
    </mounting-portal>
    <mounting-portal
        v-if="hasPortal('login_portal')"
        mount-to="#login_portal"
        name="login_portal">
      <login-portal v-bind="portalData('login_portal')"/>
    </mounting-portal>
      <mounting-portal
              v-if="hasPortal('signup_cart_portal')"
              mount-to="#signup_cart_portal"
              name="signup_cart">
          <signup-cart  @cart-expired="cartExpired"/>
      </mounting-portal>
    <mounting-portal
        v-if="hasPortal('cart_timer_portal')"
        mount-to="#cart_timer_portal"
        name="cart_timer">
      <cart-timer id="cartTimer" />
    </mounting-portal>
    <template v-for="(value, name) in mounts">
      <mounting-portal
          :mount-to="`#${name}`"
          :name="name"
          :key="name"
      >
        <component v-bind:is="slugify(value.component)" v-bind="value.value"></component>
      </mounting-portal>
    </template>
    <notifications
        group="enrollment"
        position="top right"
        :duration="5000"
        :speed="400"
    ></notifications>
    <notifications
        group="system"
        position="top center"
        :duration="5000"
        :speed="200"
    ></notifications>
    <release-badge />
  </div>
</template>

<script>

import { InitialState } from '@/utilities/cs-utilities'
import { isEmpty } from '@/utilities/utilities'
import { slugify } from '@/utilities/stringFormatters'
import ReleaseBadge from '@/components/controls/ReleaseBadge'

import {
  UPDATE_PROGRAM,
  UPDATE_YEAR,
  UPDATE_MEMBER,
  SET_REGISTRATION_ID,
  FORCE_PAY_UNPAID,
  HAS_UNPAID_REGISTRATIONS, UPDATE_REOPENED_COURSES
} from '@/constants/mutation-types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'App',
  components: {
    ReleaseBadge,
    SignupCart: () => import(/* webpackChunkName: "SignupCart" */ '@/components/cart/SignupCart'),
    CartTimer: () => import(/* webpackChunkName: "CartTimer" */ '@/components/cart/CartTimer')

  },
  data () {
    return {
      portals: {},
      mounts: {},
      isRegistrationView: false
    }
  },
  created () {
    const initialState = InitialState()
    console.log('initialState', initialState)
    if (!isEmpty(initialState.auth_member)) {
      this.updateMember(initialState.auth_member)
    } else {
      this.updateMember(null)
    }
    console.log('created.program', window.program)
    console.log('created.year', window.year)
    if (!isEmpty(initialState.program)) {
      this.updateProgram(initialState.program)
    }
    if ('YEAR' in initialState) {
      this.updateYear(initialState.YEAR)
    }
    if ('year' in initialState) {
      this.updateYear(initialState.year)
    }
    if ('REGISTRATION_ID' in initialState) {
      this[SET_REGISTRATION_ID](initialState.REGISTRATION_ID)
    }
    const url = new URL(document.location.href)
    this.isRegistrationView = url.pathname.includes('/registrations/view')
  },
  mounted () {
    this.initializePortals()
    this.initializeMounts()
  },
  computed: {
    ...mapGetters(['program', 'year', 'loggedIn', 'isAdmin', 'hasReopenedCourses']),
    initialState () {
      return InitialState()
    },
    loggedIn () {
      const initialState = InitialState()
      if (!isEmpty(initialState.auth_member)) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations([
      UPDATE_PROGRAM,
      UPDATE_YEAR,
      UPDATE_MEMBER,
      SET_REGISTRATION_ID,
      FORCE_PAY_UNPAID,
      HAS_UNPAID_REGISTRATIONS
    ]),
    ...mapActions([UPDATE_REOPENED_COURSES]),
    hasPortal (portalName) {
      return portalName in this.portals
    },
    cartExpired (payload) {
      if (this.isRegistrationView) {
        alert('Cart has expired, pending signups and balance due are no longer valid.  Page will reload')
        window.location.reload()
      }
    },
    portalData (portalName) {
      if (!isEmpty(this.portals[portalName])) {
        const portalData = this.portals[portalName]
        console.log('portalData', portalData)
        if (typeof portalData === 'string') {
          return JSON.parse(portalData)
        } else {
          return portalData
        }
      }
    },
    ...mapMutations({
      updateMember: UPDATE_MEMBER
    }),
    slugify,
    initializePortals () {
      const initialState = InitialState()
      console.log('initialState.portals', initialState.portals)
      const portals = initialState.portals
      for (const portalsKey in portals) {
        if (Object.prototype.hasOwnProperty.call(portals, portalsKey)) {
          const state = portals[portalsKey]
          console.log('state', state)
          portals[portalsKey] = JSON.parse(portals[portalsKey])
        }
      }
      this.portals = portals
    },
    initializeMounts () {
      const initialState = InitialState()
      console.log('initialState.mounts', initialState.mounts)
      const mounts = initialState.mounts
      if (typeof mounts !== 'undefined') {
        for (const [key, state] of Object.entries(mounts)) {
          try {
            this.mounts[key] = JSON.parse(state)
          } catch (e) {
            console.error('initializeMounts error: ', e, key, state)
          }
        }
      }
    }
  }
}
</script>

<style>
#app {
  font-family: ui-sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
