import { Model } from '@vuex-orm/core'
import InformationCard from './InformationCard'
import Student from './Student'
import Contact from './Contact'
import Relationship from './Relationship'

export default class InformationCardContact extends Model {
  static entity = 'informationCardContacts';

  static fields () {
    return {
      id: this.attr(null),
      student_id: this.attr(null),
      information_card_id: this.attr(null),
      contact_id: this.attr(null),
      member_id: this.attr(null),
      relationship_id: this.attr(null),
      is_custodial: this.attr(null),
      is_resident: this.attr(null),
      can_pickup: this.attr(null),
      created: this.attr(null),
      modified: this.attr(null),
      sort: this.number(0),
      contact: this.belongsTo(Contact, 'contact_id'),
      student: this.belongsTo(Student, 'student_id'),
      relationship: this.belongsTo(Relationship, 'relationship_id'),
      information_cards: this.belongsTo(InformationCard, 'information_card_id')
    }
  }

  get name () {
    return this.contact.name
  }
}
