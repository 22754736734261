import { Model } from '@vuex-orm/core'

export default class Phone extends Model {
  static entity = 'phones';

  static fields () {
    return {
      id: this.attr(null),
      person_id: this.attr(null),
      phone_number: this.attr(null),
      type: this.attr(null)
    }
  }
}
