import { Model } from '@vuex-orm/core'
import Member from './Member'
import Student from './Student'
import Program from './Program'
import RegistrationStudent from './RegistrationStudent'
export default class Registration extends Model {
  static entity = 'registrations';

  static fields () {
    return {
      id: this.attr(null),
      date: this.attr(null),
      member_id: this.attr(null),
      amountDue: this.attr(null),
      amountPaid: this.attr(null),
      balance: this.attr(null),
      closed: this.attr(null),
      credit: this.attr(null),
      fee: this.attr(null),
      registrationFee: this.attr(null),
      subTotal: this.attr(null),
      type: this.attr(null),
      year: this.attr(null),
      hasGaps: this.attr(null),
      gaps: this.attr(null),
      hasOverlaps: this.attr(null),
      overlaps: this.attr(null),
      pendingAmountDue: this.attr(null),
      pendingSubTotal: this.attr(null),
      pendingRegistrationFee: this.attr(null),
      pendingDiscountTotal: this.attr(null),
      pending_enrollments: this.attr(null),
      has_lunch_gap: this.attr(null),
      lunch_gaps: this.attr(null),
      needs_sizes: this.attr(null),
      balance_due: this.number(0),
      monthly_due: this.number(0),
      payment_required: this.boolean(false),
      validated_students: this.attr(null),
      member: this.belongsTo(Member, 'member_id'),
      program: this.belongsTo(Program, 'type', 'type'),
      students: this.belongsToMany(
        Student,
        RegistrationStudent,
        'student_id',
        'registration_id'
      )
    }
  }
}
