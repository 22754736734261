import { Model } from '@vuex-orm/core'

export default class MemberStudent extends Model {
  static entity = 'memberStudents';

  static primaryKey = ['member_id', 'student_id'];

  static fields () {
    return {
      id: this.attr(null),
      role_id: this.attr(null),
      user_id: this.attr(null),
      can_register: this.boolean(false).nullable(),
      can_signout: this.boolean(false).nullable(),
      can_schedule: this.boolean(false).nullable()
    }
  }
}
